import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonLoading,
  IonRouterOutlet,
  setupIonicReact,
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import Main from "./pages/Main";
import Login from "./pages/Login";
import { AppContextProvider } from "./data/AppContext";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

//import "./theme/custom.css";

import { connect } from "./data/connect";
import { loadUserData, setDarkMode } from "./data/user/user.actions";
import Kicked from "./pages/Kicked";

setupIonicReact();

const App: React.FC = () => (
  <AppContextProvider>
    <MainAppConnected />
  </AppContextProvider>
);

export default App;

interface StateProps {
  isLoading: boolean;
  darkMode: boolean;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
}

interface MainAppProps extends StateProps, DispatchProps {
  setDarkMode: typeof setDarkMode;
}

const MainApp: React.FC<MainAppProps> = ({
  isLoading,
  darkMode,
  loadUserData,
  setDarkMode,
}) => {
  const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    setDarkMode(prefersDark.matches);
    prefersDark.addListener((mediaQuery) => setDarkMode(mediaQuery.matches));
  }, [prefersDark.matches]);

  // Listen for changes to the prefers-color-scheme media query

  useEffect(() => {
    loadUserData();
    // eslint-disable-next-line
  }, []);

  /*window.addEventListener("scroll", (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  });*/

  return isLoading ? (
    <IonLoading
      cssClass="my-custom-class"
      isOpen={true}
      onDidDismiss={() => {}}
      message={"Bitte warten..."}
      duration={5000}
    />
  ) : (
    <IonApp className={`${darkMode ? "dark" : ""}`}>
      <IonReactHashRouter>
        <IonRouterOutlet>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route
            path="/blacklist/:reason(block|kick|spam)"
            component={Kicked}
          />
          <Route path="/chat">
            <Main />
          </Route>
          <Redirect exact to="/chat/room" />
        </IonRouterOutlet>
      </IonReactHashRouter>
    </IonApp>
  );
};

const MainAppConnected = connect({
  mapStateToProps: (state) => ({
    isLoading: state.user.loading,
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: { loadUserData, setDarkMode },
  component: MainApp,
});
