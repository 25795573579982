import { connect } from "../data/connect";
import {
  IonAvatar,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
} from "@ionic/react";
import Room from "./Room";
import React, { FC } from "react";
import { female, male, maleFemale } from "ionicons/icons";

interface StateProps {
  myId: string;
  cRoom: string;
  privs: any;
  onlinelist: any;
}

interface DispatchProps {}

interface OnlineListProps extends StateProps, DispatchProps {}

const OnlineList: FC<OnlineListProps> = ({ myId, privs, onlinelist }) => {
  const getPrivTitle = (priv: string) => {
    if (privs[priv]) return privs[priv].title;
    return "";
  };
  let pRoom = "0";
  return onlinelist.map((ol: any) => {
    let showRoom = false;
    if (pRoom !== ol.room_id) {
      pRoom = ol.room_id;
      showRoom = true;
    }
    return (
      <React.Fragment key={ol.user_id}>
        {showRoom && (
          <Room
            key={"r" + ol.room_id}
            room={ol.room}
            room_id={ol.room_id}
            room_allowed={ol.room_allowed}
          />
        )}
        <IonMenuToggle auto-hide="false">
          <IonItem
            detail={false}
            disabled={ol.user_id == myId}
            routerLink={"/chat/" + ol.user_id}
            routerDirection="none"
            /*button
            onClick={() => history.push(`/chat/${ol.user_id}`)}*/
          >
            <IonAvatar slot="start">
              <img
                src={"https://www.cleefchat.de" + ol.user_avatar}
                alt={ol.user}
              />
            </IonAvatar>
            <IonLabel>
              <h2>
                {genIcon(ol.user_sex)} {ol.user}
              </h2>
              <p>
                {ol.user_priv !== "gast" && (
                  <>
                    <img
                      src={`https://www.cleefchat.de/img/${ol.user_priv}_i.png`}
                      alt={ol.user_priv}
                      width={16}
                      height={16}
                    />{" "}
                    {getPrivTitle(ol.user_priv)}
                  </>
                )}
                {"  "}
                {ol.user_simg !== "" && (
                  <>
                    <img
                      src={`https://www.cleefchat.de/img/${ol.user_simg}.png`}
                      alt={ol.user_stext}
                      width={16}
                      height={16}
                    />{" "}
                    {ol.user_stext}
                  </>
                )}
              </p>
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
      </React.Fragment>
    );
  });
};
const genIcon = (gen: string) => {
  let icon = maleFemale;
  let color = "dark";
  if (gen === "f") icon = female;
  if (gen === "m") icon = male;
  if (gen === "f") color = "danger";
  if (gen === "m") color = "primary";
  return <IonIcon icon={icon} color={color} slot="start" />;
};
export default connect({
  mapStateToProps: (state) => ({
    cRoom: state.messages.room,
    myId: state.user.user_id,
    privs: state.user.user_all_privs,
  }),
  mapDispatchToProps: {},
  component: OnlineList,
});
