import { UserActions } from "./user.actions";
import { UserState } from "./user.state";

export function userReducer(state: UserState, action: UserActions): UserState {
  switch (action.type) {
    case "set-user-loading":
      return { ...state, loading: action.isLoading };
    case "set-user-data":
      return { ...state, ...action.data };
    case "change-status":
      return { ...state, current_status: action.status };
    case "pv-lock":
      return { ...state, lockprivat: action.pvlock };
    case "sys-message":
      return { ...state, sys_messages: action.sysmess };
    case "set-avatar":
      return { ...state, avatar: action.avatar };
    case "set-dark-mode":
      return { ...state, darkMode: action.darkMode };
    case "set-split":
      return { ...state, split: action.split };
    case "set-daily-prize":
      return {
        ...state,
        dailyPrize: action.dailyPrize,
        dailyPrizeDays: action.days,
      };
    case "logout":
      return { ...state, isLoggedin: false };
  }
}
