import {
  IonButtons,
  IonContent,
  IonToolbar,
  IonHeader,
  IonPage,
  IonBackButton,
  IonAvatar,
  IonLabel,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  useIonModal,
  useIonAlert,
} from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";

import { connect } from "../data/connect";
import { reloaderUserOnline } from "../data/messages/messages.actions";
import { Recent } from "../models/Recent";
import { getUserOnlineById } from "../data/selectors";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import { friendRequest, sendBlockUser } from "../data/chatApi";
import Melden from "../sections/Melden";
import UserStatistics from "../sections/UserStatistics";

interface StateProps {
  chat: Recent;
  kick_perms: boolean;
  onlinelist: any;
  myId: string;
  room: string;
}
interface DispatchProps {
  reloaderUserOnline: typeof reloaderUserOnline;
}
interface ProfileProps extends RouteComponentProps, StateProps, DispatchProps {}

const Profile: React.FC<ProfileProps> = ({
  chat,
  kick_perms,
  onlinelist,
  reloaderUserOnline,
  history,
  myId,
  room,
}) => {
  const [block, setBlock] = useState("0");
  const { id } = useParams<{ id: string }>();
  const [present] = useIonAlert();

  const user = useMemo(() => {
    return getUserOnlineById(onlinelist, id);
  }, [id, onlinelist]);

  useEffect(() => {
    sendBlockUser({ show: id }).then((res: any) => {
      if (res === "all") setBlock("2");
      else if (res === "priv") setBlock("1");
      else setBlock("0");
    });
    // eslint-disable-next-line
  }, []);

  const sendBlock = (value: any) => {
    setBlock(value);
    sendBlockUser({ block_all: id, blocktype: value });
  };

  const handleFriendRequest = () => {
    friendRequest({ to: id }).then((res: any) => {
      if (res === "1") {
        reloaderUserOnline(myId, room);
        present({
          message: `die freundschaftsanfrage an ${chat.user} wurde verschickt!`,
          buttons: ["Ok"],
        });
      } else present({ message: res, buttons: ["Ok"] });
    });
  };

  const dismissMelden = () => {
    hideMelden();
  };
  const [showMelden, hideMelden] = useIonModal(Melden, {
    user_id: id,
    onDismiss: dismissMelden,
  });

  const statisticsDismiss = () => {
    closeStatistics();
  };
  const [showStatistics, closeStatistics] = useIonModal(UserStatistics, {
    user_id: id,
    onDismiss: statisticsDismiss,
  });
  return (
    <IonPage>
      <IonHeader collapse="fade" translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/chat/room" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          className="ion-padding"
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IonAvatar>
            <img
              src={`https://www.cleefchat.de/${chat.user_avatar}`}
              alt={chat.user}
            />
          </IonAvatar>
          <h1>{chat.user}</h1>
        </div>
        <IonList>
          <IonItem
            href={`https://www.cleefchat.de/profile/${chat.user}`}
            target="_blank"
          >
            Webprofil ansehen
          </IonItem>
          <IonItem button onClick={() => showStatistics()}>
            Benutzer Statistik
          </IonItem>
          {user.friend === "null" && (
            <IonItem button onClick={() => handleFriendRequest()}>
              Freundschaftsanfrage
            </IonItem>
          )}
          <IonItem button onClick={() => showMelden()}>
            Chatter melden
          </IonItem>
          <IonItem>
            <IonLabel>Nachrichten Blockieren</IonLabel>
            <IonSelect
              value={block}
              onIonChange={(e) => sendBlock(e.detail.value)}
            >
              <IonSelectOption value="0">frei</IonSelectOption>
              <IonSelectOption value="1">nur private</IonSelectOption>
              <IonSelectOption value="2">alle</IonSelectOption>
            </IonSelect>
          </IonItem>
          {kick_perms && (
            <IonItem button onClick={() => history.push("/chat/kick/" + id)}>
              Kick User
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default connect({
  mapStateToProps: (state, OwnProps) => ({
    myId: state.user.user_id,
    room: state.messages.room,
    kick_perms: state.user.perms_kick_user,
    onlinelist: state.messages.onlinelist,
    chat:
      state.messages.privatChats[OwnProps.match.params.id] ||
      state.messages.userInfo[OwnProps.match.params.id] ||
      getUserOnlineById(state.messages.onlinelist, OwnProps.match.params.id),
  }),
  mapDispatchToProps: { reloaderUserOnline },
  component: withRouter(Profile),
});
