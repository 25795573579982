import { combineReducers } from "./combineReducers";
import { messagesReducer } from "./messages/messages.reducer";
import { userReducer } from "./user/user.reducer";

export const initialState: AppState = {
  user: {
    isLoggedin: false,
    user_id: "0",
    username: "",
    user_priv: "gast",
    loading: false,
    darkMode: false,
    split: false,
    dailyPrize: false,
    dailyPrizeDays: "1",
  },
  messages: {
    room: "1",
    roomName: "Lobby",
    smileys: [],
    last_id: "",
    messages: [],
    privatMessages: {},
    privatChats: {},
    userInfo: {},
    unread: {},
    currentChat: "",
    onlinelist: { userOnline: [], all_empty_rooms: [] },
    notification: "",
    coins: "",
  },
};

export const reducers = combineReducers({
  messages: messagesReducer,
  user: userReducer,
});

export type AppState = ReturnType<typeof reducers>;
