import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
} from "@ionic/react";
import { getNotifications, setNotifications } from "../data/chatApi";
import RegisterWin from "../components/RegisterWin";

interface Props {
  user_id: string;
  priv: string;
  onDismiss: () => void;
  history: any;
}

interface NotificationsProps extends Props {}

const Notifications: React.FC<NotificationsProps> = ({
  user_id,
  priv,
  onDismiss,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    getNotifications().then((res: any) => {
      setLoading(false);
      if (res.result) {
        setData(res.data);
      }
    });
  }, []);
  const clearNotifications = () => {
    setNotifications({ readid: "all" });
    setData([]);
  };
  const itemChooseHandler = (id: any, cat: any) => {
    setNotifications({ readid: cat });
    // ofline_mess , coinstransfer
    if (cat === "pinnwand") history.push("/chat/pinnwand");
    if (cat === "friend") history.push("/chat/friends");
    if (cat.startsWith("pv_")) {
      const ex = cat.split("_");
      history.push("/chat/" + ex[1]);
    }
    onDismiss();
  };
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                clearNotifications();
              }}
            >
              löschen
            </IonButton>
          </IonButtons>
          <IonTitle>Benachrichtigungen</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {priv == "gast" ? (
          <RegisterWin
            reason="Benachrichtigungen"
            dismiss={() => onDismiss()}
          />
        ) : (
          <>
            {loading && <IonSpinner />}
            {!loading && (
              <IonList>
                {!!data.length &&
                  data.map((item: any) => (
                    <IonItem
                      key={item.id}
                      onClick={() => itemChooseHandler(item.id, item.cat)}
                    >
                      <IonLabel>
                        <h2>{item.title}</h2>
                        <p>{item.date}</p>
                      </IonLabel>
                      {!item.read && <IonBadge slot="end"> </IonBadge>}
                    </IonItem>
                  ))}
              </IonList>
            )}
          </>
        )}
      </IonContent>
    </>
  );
};

export default Notifications;
