import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonToolbar,
  IonInput,
  IonHeader,
  IonTitle,
  IonMenuButton,
  IonPage,
  useIonModal,
  IonBadge,
  useIonViewWillEnter,
  IonFab,
  IonFabButton,
} from "@ionic/react";
import React, { useRef, useEffect, useState } from "react";
import ChatBubble from "../components/ChatBubble";
import Smileys from "../sections/Smileys";
import UploadPicture from "../sections/UploadPicture";
import {
  happyOutline,
  paperPlaneOutline,
  cameraOutline,
  notificationsOutline,
  personCircleOutline,
  chevronDownOutline,
} from "ionicons/icons";

import { connect } from "../data/connect";
import {
  sendMessage,
  setCurrentChat,
  setRead,
} from "../data/messages/messages.actions";
import { userCallbackFunctions as UCF } from "../data/user/user.actions";
import { Message } from "../models/Message.js";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Notifications from "../sections/Notifications";

interface StateProps {
  room: string;
  roomName: string;
  myId: string;
  priv: string;
  last_id: string;
  messages: Message[];
  smileys: object;
  notification: string;
  unread: number;
  unreadChats: any;
  split: boolean;
}

interface DispatchProps {
  UCF: typeof UCF;
  sendMessage: typeof sendMessage;
  setCurrentChat: typeof setCurrentChat;
  setRead: typeof setRead;
}

interface RoomProps extends RouteComponentProps, StateProps, DispatchProps {}

const Room: React.FC<RoomProps> = ({
  room,
  roomName,
  messages,
  smileys,
  myId,
  priv,
  last_id,
  notification,
  unread,
  unreadChats,
  history,
  split,
  setCurrentChat,
  sendMessage,
  setRead,
  UCF,
}) => {
  const contentRef = useRef<any>(null);
  const [sending, setSending] = useState(false);
  const [float, setFloat] = useState(false);
  const [unseen, setUnseen] = useState(0);
  const [text, setText] = useState("");

  const callback = (res: string) => {
    UCF(res, history);
  };

  var upscroll = function () {
    contentRef.current.scrollToBottom(0);
    if (unread > 0) setRead("room");
  };
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.getScrollElement().then((elem: any) => {
        // console.log(
        //   `effect message ${elem.scrollHeight} < ${elem.scrollTop} + ${elem.offsetHeight} + 150`
        // );
      });
    }
    if (!float) {
      setUnseen(0);
      upscroll();
    }
    // eslint-disable-next-line
  }, [messages]);

  var scrolluptounread = (unseen: any) => {
    if (contentRef.current) {
      contentRef.current.getScrollElement().then((elem: any) => {
        setTimeout(() => {
          var el = document.getElementById("unseen_room");
          if (unseen > 0 && el) {
            // console.log(el.offsetTop);
            contentRef.current.scrollToPoint(0, el.offsetTop, 300);
          } else contentRef.current.scrollToBottom(0);
        }, 20);
      });
    }
  };

  useEffect(() => {
    scrolluptounread(unseen);
    // console.log(`unseen ${unseen}`);
  }, [unseen]);

  // useEffect(() => {
  //   console.log(`effect unread ${unread}`);
  // }, [unread]);

  useIonViewWillEnter(() => {
    setUnseen(unread);
    //scrolluptounread(unread);
    setCurrentChat("");
  }, [unread]);

  const getSlicedMessages = (messages: any, count: number) => {
    const keys = Object.keys(messages);
    keys.sort(function (a: any, b: any) {
      return a - b;
    });
    let endpoint = {};
    keys.slice(-30).forEach((item: any) => {
      endpoint = { ...endpoint, [item]: { ...messages[item] } };
    });
    return endpoint;
  };

  const sendHandler = (e: any) => {
    if (text === "") return false;
    setSending(true);
    sendMessage(
      {
        room: room,
        message: text,
        privat: 0,
        last_id,
        screenw: window.innerWidth,
        screenh: window.innerHeight,
      },
      myId,
      callback
    );
    setText("");
    setSending(false);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      sendHandler(event);
    }
  };
  const paste = (str: string) => {
    setText(text + "" + str);
  };
  const selectSmiley = (code: string) => {
    setText(text + "" + code);
    dismiss();
  };
  const handleDismiss = () => {
    dismiss();
  };
  const [present, dismiss] = useIonModal(Smileys, {
    smileys: smileys,
    choose: selectSmiley,
    onDismiss: handleDismiss,
  });
  const notificationsDismiss = () => {
    notifdiss();
  };
  const [notifications, notifdiss] = useIonModal(Notifications, {
    onDismiss: notificationsDismiss,
    priv,
    history,
  });
  const setPhoto = (code: string) => {
    setText(text + "" + code);
    upClose();
  };
  const uploadDismiss = () => {
    upClose();
  };
  const [upload, upClose] = useIonModal(UploadPicture, {
    priv,
    setPhoto,
    onDismiss: uploadDismiss,
  });
  let count = 0;
  Object.keys(unreadChats).forEach((item: any) => {
    if (item !== "room" && Object.keys(unreadChats[item]).length > 0) count++;
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
            {count > 0 && !split && <IonBadge color="danger">{count}</IonBadge>}
          </IonButtons>
          <IonTitle>{roomName}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => notifications()}>
              <IonIcon icon={notificationsOutline} slot="icon-only" />
              {parseInt(notification) > 0 && (
                <IonBadge className="custom-badge" color="danger">
                  {notification}
                </IonBadge>
              )}
            </IonButton>
            <IonButton onClick={(e) => history.push("/chat/setting")}>
              <IonIcon icon={personCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        ref={contentRef}
        scrollEvents
        onIonScroll={async () => {
          var el = await contentRef.current.getScrollElement();
          // console.log(el.scrollTop, el.offsetHeight, el.scrollHeight);
          if (el.scrollHeight < el.scrollTop + el.offsetHeight + 150) {
            setFloat(false);
            if (unread > 0) setRead("room");
          } else setFloat(true);
        }}
      >
        <ChatBubble
          messages={getSlicedMessages(messages, 30)}
          unread={unseen}
          paste={paste}
        />

        {float && (
          <IonFab
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            onClick={() => upscroll()}
          >
            <IonFabButton color="light">
              <IonIcon icon={chevronDownOutline} />
            </IonFabButton>
            {unread > 0 && (
              <IonBadge
                color="danger"
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                {unread}
              </IonBadge>
            )}
          </IonFab>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="primary"
              shape="round"
              slot="start"
              onClick={() => {
                present();
              }}
            >
              <IonIcon icon={happyOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons>
            <IonInput
              value={text}
              placeholder="Message"
              disabled={sending}
              onIonChange={(e) => setText(e.detail.value || "")}
              enterkeyhint="send"
              onKeyUp={handleKeyPress}
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              shape="round"
              slot="start"
              onClick={() => upload()}
            >
              <IonIcon icon={cameraOutline} slot="icon-only" />
            </IonButton>
            {text.length > 0 && (
              <IonButton
                color="primary"
                shape="round"
                slot="end"
                onClick={sendHandler}
              >
                <IonIcon icon={paperPlaneOutline} slot="icon-only" />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    room: state.messages.room,
    roomName: state.messages.roomName,
    myId: state.user.user_id,
    priv: state.user.user_priv,
    last_id: state.messages.last_id,
    smileys: state.messages.smileys,
    messages: state.messages.messages,
    notification: state.messages.notification,
    unread: (state.messages.unread["room"] || []).length,
    unreadChats: state.messages.unread,
    split: state.user.split,
  }),
  mapDispatchToProps: { sendMessage, setRead, setCurrentChat, UCF },
  component: withRouter(Room),
});
