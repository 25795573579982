import { connect } from "../data/connect";
import { textFormat, unixToTime } from "../data/selectors";
import { IonIcon, useIonActionSheet } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { female, male, maleFemale } from "ionicons/icons";
import { Message } from "../models/Message";
import React from "react";

interface StateProps {
  myid: string;
  mess: any;
  pv?: boolean;
  kick_perms: boolean;
  privs: any;
  paste: (str: string) => void;
}

interface DispatchProps {}

interface MessageProps extends RouteComponentProps, StateProps, DispatchProps {}

const Messages: React.FC<MessageProps> = ({
  myid,
  pv = false,
  mess,
  history,
  paste,
  kick_perms,
  privs,
}) => {
  //const [current, setCurrent] = useState();
  const [present, dismiss] = useIonActionSheet();

  const getButtons = ({ user_id, username }: any) => {
    let buttons: object[] = [];
    buttons.push({
      text: "private Nachricht",
      handler: () => {
        history.push("/chat/" + user_id);
      },
    });
    buttons.push({
      text: "Nick kopieren",
      handler: () => paste("@" + username),
    });
    if (kick_perms)
      buttons.push({
        text: "Kick User",
        role: "destructive",
        handler: () => {
          history.push("/chat/kick/" + user_id);
          //dismiss();
          //setCurrent(user_id);
          //kickBox()
        },
      });
    buttons.push({ text: "Cancel", role: "cancel" });
    return {
      buttons,
      header: username,
    };
  };

  /*const dismissKick = () => {
    hideKick();
  };
  const [kickBox, hideKick] = useIonModal(KickBox, {
    user_id: current,
    onDismiss: dismissKick,
  });*/

  const myEvent = (id: string) => {
    if (id.slice(0, 9) === "smilchat-") {
      const ex = id.split("-");
      paste(ex[1]);
      setTimeout(() => dismiss(), 50);
    }
  };

  const genIcon = (gen: string) => {
    let icon = maleFemale;
    let color = "dark";
    if (gen === "f") icon = female;
    if (gen === "m") icon = male;
    if (gen === "f") color = "danger";
    if (gen === "m") color = "primary";
    return <IonIcon icon={icon} color={color} slot="start" />;
  };
  const getPrivColor = (priv: string) => {
    if (privs[priv]) return privs[priv].color;
    return "";
  };
  return mess.map((msg: Message, key: number) => {
    return (
      <div
        className={`message${mess.length === key + 1 ? " last" : ""}`}
        key={msg.id}
        onClick={() => {
          var found = msg.message.match(/id="adminy_([0-9]+)"/i);
          var name = msg.message.match(/<b[^>]*>([^<]+)<\/b>/i);
          if (found && name)
            present(getButtons({ user_id: found[1], username: name[1] }));
          if (msg.user_id !== "1" && msg.user_id !== myid && !pv)
            present(getButtons({ user_id: msg.user_id, username: msg.user }));
        }}
      >
        {key === 0 && msg.user_id !== "1" && msg.user_id !== myid && !pv ? (
          <>
            {genIcon(msg.sex)}{" "}
            {msg.priv !== "gast" && (
              <>
                <img
                  src={`https://www.cleefchat.de/img/${msg.priv}_i.png`}
                  alt={msg.priv}
                  width={16}
                  height={16}
                  style={{ verticalAlign: "middle" }}
                />{" "}
              </>
            )}
            <span style={{ color: getPrivColor(msg.priv) }}>{msg.user}</span>
            <br />
          </>
        ) : (
          ""
        )}
        <span
          onClick={(e) => myEvent((e.target as HTMLTextAreaElement).id)}
          dangerouslySetInnerHTML={{
            __html: textFormat(msg.message),
          }}
        ></span>
        <span className="time"> {unixToTime(msg.time)}</span>
      </div>
    );
  });
};

export default connect({
  mapStateToProps: (state) => ({
    myid: state.user.user_id,
    kick_perms: state.user.perms_kick_user,
    privs: state.user.user_all_privs,
  }),
  mapDispatchToProps: {},
  component: withRouter(Messages),
});
