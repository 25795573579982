import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonList,
  IonItem,
  useIonAlert,
  IonInput,
  IonText,
} from "@ionic/react";
import { connect } from "../data/connect";
import { fetchChangePw } from "../data/chatApi";
import { logout } from "../data/user/user.actions";

interface StateProps {
  user_id: string;
  priv: string;
  onDismiss: () => void;
}

interface DispatchProps {
  logout: typeof logout;
}

interface RegisterPwProps extends StateProps, DispatchProps {}

const RegisterPw: React.FC<RegisterPwProps> = ({
  user_id,
  priv,
  logout,
  onDismiss,
}) => {
  const [present] = useIonAlert();
  const [fields, setFields] = useState({ pw: "", repeatpw: "" });
  const [logoutLay, setLogoutLay] = useState(false);

  const changePwHandler = () => {
    if (fields.pw.length < 1) {
      present({ message: "Please enter a new password", buttons: ["ok"] });
      return false;
    }
    // eslint-disable-next-line
    if (fields.pw != fields.repeatpw) {
      present({
        message: "The new password and repeat aren't identicla!",
        buttons: ["ok"],
      });
      return false;
    }
    fetchChangePw({ user_pw: fields.pw }).then((res: any) => {
      // eslint-disable-next-line
      if (res == "1") {
        setLogoutLay(true);
        setTimeout(() => {
          logout();
        }, 5000);
      } else present({ message: "Error " + res });
    });
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Register Password</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {logoutLay ? (
          <p className="ion-paddog">
            <IonText>
              Vielen Dank für Ihre Registrierung. Beim erneuten Anmelden im Chat
              werden Sie zur Passworteingabe aufgefordert.
            </IonText>
            <IonButton expand="block" onClick={() => logout()}>
              Chat verlassen
            </IonButton>
          </p>
        ) : (
          <>
            <p className="ion-padding">
              Durch die Vergabe eines Passwortes können Sie Ihren Chatnamen
              dauerhaft reservieren
            </p>
            <IonList>
              <IonItem>
                <IonInput
                  value={fields.pw}
                  placeholder="Password"
                  type="password"
                  onIonChange={(e) =>
                    setFields({ ...fields, pw: e.detail.value || "" })
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonInput
                  value={fields.repeatpw}
                  placeholder="Repeat Password"
                  type="password"
                  onIonChange={(e) =>
                    setFields({ ...fields, repeatpw: e.detail.value || "" })
                  }
                ></IonInput>
              </IonItem>
            </IonList>
            <IonButton expand="block" onClick={() => changePwHandler()}>
              Register Password
            </IonButton>
          </>
        )}
      </IonContent>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    priv: state.user.user_priv,
  }),
  mapDispatchToProps: { logout },
  component: RegisterPw,
});
