import React, { useEffect, useState } from "react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonListHeader,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  useIonAlert,
} from "@ionic/react";
import { fetchUpload, uploadFile } from "../data/chatApi";
import { cameraOutline, imagesOutline } from "ionicons/icons";
import RegisterWin from "../components/RegisterWin";

interface StateProps {
  priv: string;
  setPhoto: any;
  onDismiss: any;
}

const UploadPicture: React.FC<StateProps> = ({ priv, setPhoto, onDismiss }) => {
  const [present] = useIonAlert();
  const [data, setData] = useState([]);
  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });
    let blob = await fetch(photo.webPath!).then((r) => r.blob());
    upload(blob);
  };
  const pickImages = async () => {
    const photo = await Camera.pickImages({
      quality: 100,
    });
    photo.photos.forEach(async (img: any) => {
      const response = await fetch(img.webPath!);
      const blob = await response.blob();
      await uploadFile(blob);
      getPreviousUploads();
    });
  };
  const upload = (photo: any) => {
    uploadFile(photo).then((res: any) => {
      getPreviousUploads();
    });
  };
  const deleteHandler = (id: any) => {
    present({
      message: `Bist du sicher?`,
      buttons: [
        { text: "Nein", role: "cancel" },
        {
          text: "Ja, Ich bin",
          handler: () => {
            fetchUpload({ deleteimg: id });
            setData(data.filter((item: any) => item.id !== id));
          },
        },
      ],
    });
  };
  const getPreviousUploads = () => {
    fetchUpload({ getinfo: "yes" }).then((res: any) => {
      setData(res.files);
    });
  };
  useEffect(() => {
    getPreviousUploads();
  }, []);
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Bilder hochladen</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {priv == "gast" ? (
          <RegisterWin reason="Bilder hochladen" dismiss={() => onDismiss()} />
        ) : (
          <>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    color="success"
                    expand="block"
                    onClick={() => pickImages()}
                  >
                    <IonIcon icon={imagesOutline} slot="start" />
                    Galerie öffnen
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton expand="block" onClick={() => takePhoto()}>
                    <IonIcon icon={cameraOutline} slot="start" />
                    Kamera öffnen
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonList>
              {data.length > 0 && (
                <>
                  <IonListHeader>Zuletzt hochgeladene Dateien</IonListHeader>
                  {data.map((item: any) => (
                    <IonItemSliding key={item.id}>
                      <IonItem
                        onClick={() => {
                          setPhoto(`[${item.type}]${item.path}[/${item.type}]`);
                        }}
                      >
                        {item.type === "img" && (
                          <img
                            src={`https://www.cleefchat.de/upload/${item.path}`}
                            width={50}
                            height={50}
                            alt={item.filename}
                            style={{ borderRadius: 5, marginRight: 10 }}
                          />
                        )}
                        <IonLabel>
                          <h2>{item.filename}</h2>
                          <p>{item.time}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItemOptions side="end">
                        <IonItemOption
                          color="danger"
                          expandable
                          onClick={() => deleteHandler(item.id)}
                        >
                          Löschen
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  ))}
                </>
              )}
            </IonList>
          </>
        )}
      </IonContent>
    </>
  );
};

export default UploadPicture;
