import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  useIonAlert,
  IonListHeader,
  IonPage,
  IonBackButton,
} from "@ionic/react";
import { connect } from "../data/connect";
import { decodeHtml, getOnlineById } from "../data/selectors";
import { fetchKickUser } from "../data/chatApi";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";

interface StateProps {
  user: any;
}

interface KickBoxProps extends RouteComponentProps, StateProps {}

const KickBox: React.FC<KickBoxProps> = ({ user, history }) => {
  const { user_id } = useParams<{ user_id: string }>();
  const [present] = useIonAlert();
  const kickUser = (time: string, reason: string = "") => {
    fetchKickUser({ user_id, time, reason }).then((res: any) => {
      history.goBack();
    });
  };
  const kickUserAsk = (time: string) => {
    if (parseInt(time) > 0)
      present({
        header: "Grund?!",
        message: "warum wollen sie ihn kicken ?",
        inputs: [
          {
            name: "grund",
            type: "text",
            placeholder: "grund...",
          },
        ],
        buttons: [
          { text: "Cancel", role: "cancel" },
          {
            text: "Kick",
            handler: (d: any) => {
              kickUser(time, d.grund);
            },
          },
        ],
        //onDidDismiss: () => {},
      });
    else
      present({
        header: "Alert",
        message: "Bist du sicher ?",
        buttons: [
          { text: "Cancel", role: "cancel" },
          {
            text: "Ja, Ich bin",
            handler: () => {
              kickUser(time);
            },
          },
        ],
        //onDidDismiss: () => {},
        //backdropDismiss: false,
      });
    return false;
  };
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/chat/room" />
          </IonButtons>
          <IonTitle>Kick User</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {user /*<b>' + user_name + '</b> <i>(' + user_ip + ')</i>' + mit_vpn + '<br><br><table><tr><td>Country: </td><td><img src="/img/flags/' + countryCode + '.png"> ' + country + '</td></tr><tr><td>Region: </td><td>' + region + '</td></tr><tr><td>City: </td><td>' + city + '</td></tr><tr><td>ISP: </td><td>' + isp + '</td></tr><tr><td>Sc-Size: </td><td>' + user_screensize + '</td></tr><tr><td>Pc-Os: </td><td><img src="/img/' + user_fid + '.png" align="absbottom" width="16px" height="16px" /> ' + user_platform + '</td></tr><tr><td>Browser: </td><td><img src="/img/' + user_bid + '.png" align="absbottom" width="16px" height="16px" /> ' + user_browser + '</td></tr></table><br>\
            	<div style="position:absolute;top:90px;right:50px;" id="ipscan_map_'+ id + '"><a href="https://www.google.com/maps/@' + lat + ',' + lon + ',12z" target="_blank"><img src="/img/maps.png"></a></div>\*/ ? (
          <>
            <IonList>
              <IonListHeader>Info</IonListHeader>
              <IonItem lines="none">
                <IonGrid>
                  <IonRow>
                    <b>{user.user}</b> <i>({user.user_ip})</i>
                    {user.proxy === "1" ? " VPN/Proxy etc..." : ""}
                  </IonRow>
                  <IonRow>
                    <IonCol>Country:</IonCol>
                    <IonCol>
                      <img
                        src={`https://www.cleefchat.de/img/flags/${user.countryCode}.png`}
                        alt={user.country}
                      />{" "}
                      {decodeHtml(user.country)}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>Region:</IonCol>
                    <IonCol>{decodeHtml(user.region)}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>City:</IonCol>
                    <IonCol>{decodeHtml(user.city)}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>ISP:</IonCol>
                    <IonCol>{decodeHtml(user.isp)}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>Screen-Size:</IonCol>
                    <IonCol>{user.screensize}</IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>OS:</IonCol>
                    <IonCol>
                      <img
                        src={`https://www.cleefchat.de/img/${user.user_fid}.png`}
                        alt={user.user_platform}
                      />{" "}
                      {decodeHtml(user.user_platform)}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>Browser:</IonCol>
                    <IonCol>
                      <img
                        src={`https://www.cleefchat.de/img/${user.user_bid}.png`}
                        alt={user.user_browser}
                      />{" "}
                      {decodeHtml(user.user_browser)}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonListHeader>Kick</IonListHeader>
              <IonItem button onClick={(e) => kickUserAsk("-1")}>
                Stumm
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("-2")}>
                Stumm immer
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("0")}>
                Kick User
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("600")}>
                10 Minuten
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("1800")}>
                30 Minuten
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("3600")}>
                1 Stunde
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("10800")}>
                3 Stunden
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("86400")}>
                1 Tag
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("604800")}>
                1 Woche
              </IonItem>
              <IonItem button onClick={() => kickUserAsk("94348800")}>
                Für immer
              </IonItem>
            </IonList>
          </>
        ) : (
          <p>this user is not online anymore!</p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state, OwnProps) => ({
    user: getOnlineById(
      state.messages.onlinelist,
      OwnProps.match.params.user_id
    ),
  }),
  mapDispatchToProps: {},
  component: withRouter(KickBox),
});
