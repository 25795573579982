import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { female, male, maleFemale } from "ionicons/icons";
import { topUsers } from "../data/chatApi";

//interface FriendsProps {}

const Friends: React.FC = () => {
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [segment, setSegment] = useState("2");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);

  const loadData = () => {
    setLoading(true);
    topUsers({ json: "1", type: segment }).then((res: any) => {
      setLoading(false);
      if (res.startsWith("{")) {
        const json = JSON.parse(res);
        setData(json.data);
      }
    });
  };
  const pushData = () => {
    setInfiniteDisabled(true);
    topUsers({ json: "1", type: segment, site: page }).then((res: any) => {
      if (res.startsWith("{")) {
        const json = JSON.parse(res);
        setData([...data, ...json.data]);
        setInfiniteDisabled(false);
      }
    });
  };

  const segmentHandler = (segment: string) => {
    setPage(1);
    setInfiniteDisabled(false);
    setSegment(segment);
  };

  useEffect(() => {
    if (page > 1) pushData();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [segment]);

  const genIcon = (gen: string) => {
    let icon = maleFemale;
    let color = "dark";
    if (gen === "f") icon = female;
    if (gen === "m") icon = male;
    if (gen === "f") color = "danger";
    if (gen === "m") color = "primary";
    return <IonIcon icon={icon} color={color} slot="start" />;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Top Users</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={segment}
            onIonChange={(e) => segmentHandler(e.detail.value || "")}
          >
            <IonSegmentButton value="2">
              <IonLabel>Gesamt</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="1">
              <IonLabel>Heute</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="3">
              <IonLabel>Pokale</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        {loading && <IonSpinner />}
        <IonList>
          {!loading &&
            !!data.length &&
            data.map((item: any) => (
              <IonItem key={item.user_id}>
                {
                  // eslint-disable-next-line
                  segment == "3" && (
                    <IonGrid>
                      <IonRow>
                        <IonCol size="5">
                          {genIcon(item.user_sex)}{" "}
                          {
                            // eslint-disable-next-line
                            item.user_priv != "gast" && (
                              <>
                                <img
                                  src={`https://www.cleefchat.de/img/${item.user_priv}_i.png`}
                                  alt={item.user_priv}
                                  width={16}
                                  height={16}
                                  style={{ verticalAlign: "middle" }}
                                />{" "}
                              </>
                            )
                          }
                          {item.username}
                        </IonCol>
                        <IonCol>{item.goldcup}</IonCol>
                        <IonCol>{item.silvercup}</IonCol>
                        <IonCol>{item.bronzecup}</IonCol>
                        <IonCol>{item.total}</IonCol>
                      </IonRow>
                    </IonGrid>
                  )
                }
                {
                  // eslint-disable-next-line
                  segment != "3" && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          {genIcon(item.user_sex)}{" "}
                          {
                            // eslint-disable-next-line
                            item.user_priv != "gast" && (
                              <>
                                <img
                                  src={`https://www.cleefchat.de/img/${item.user_priv}_i.png`}
                                  alt={item.user_priv}
                                  width={16}
                                  height={16}
                                  style={{ verticalAlign: "middle" }}
                                />{" "}
                              </>
                            )
                          }
                          {item.username}
                        </IonCol>
                        <IonCol>{item.time}</IonCol>
                        <IonCol>{item.register}</IonCol>
                      </IonRow>
                    </IonGrid>
                  )
                }
              </IonItem>
            ))}
        </IonList>
        <IonInfiniteScroll
          onIonInfinite={() => {
            setPage(page + 1);
          }}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Friends;
