import React from "react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonIcon,
  useIonAlert,
} from "@ionic/react";
import { changeAvatar, fetchAvatar } from "../data/chatApi";
import { cameraOutline, imagesOutline, trashBinOutline } from "ionicons/icons";
import { connect } from "../data/connect";
import { setAvatar } from "../data/user/user.actions";

interface StateProps {
  myId: string;
  avatar: any;
  onDismiss: any;
}
interface DispatchProps {
  setAvatar: typeof setAvatar;
}

interface ChangeAvatarProps extends StateProps, DispatchProps {}

const ChangeAvatar: React.FC<ChangeAvatarProps> = ({
  myId,
  avatar,
  onDismiss,
  setAvatar,
}) => {
  const [present] = useIonAlert();
  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });
    console.log(photo);
    let blob = await fetch(photo.webPath!).then((r) => r.blob());
    upload(blob);
  };
  const pickImages = async () => {
    const photo = await Camera.pickImages({
      quality: 100,
    });
    photo.photos.forEach(async (img: any) => {
      const response = await fetch(img.webPath!);
      const blob = await response.blob();
      const res = await changeAvatar(blob);
      set(res);
    });
  };
  const set = (res: any) => {
    if (res.result == "1") setAvatar(res.url.replace("./avatars", "avatars"));
  };
  const upload = (photo: any) => {
    changeAvatar(photo).then((res: any) => {
      if (res.result == "1") set(res);
    });
  };
  const deleteHandler = () => {
    present({
      message: `Bist du sicher?`,
      buttons: [
        { text: "Nein", role: "cancel" },
        {
          text: "Ja, Ich bin",
          handler: () => {
            fetchAvatar({ delete: "yes" });
          },
        },
      ],
    });
  };
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Profilebild ändern</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <p className="ion-padding">
          <p style={{ textAlign: "center" }}>
            <img
              src={`https://www.cleefchat.de/${avatar}`}
              alt={myId}
              width={200}
              height={200}
            />
          </p>
          <IonButton
            color="success"
            expand="block"
            onClick={() => pickImages()}
          >
            <IonIcon icon={imagesOutline} slot="start" />
            Galerie öffnen
          </IonButton>
          <IonButton expand="block" onClick={() => takePhoto()}>
            <IonIcon icon={cameraOutline} slot="start" />
            Kamera öffnen
          </IonButton>
          {avatar.indexOf("avatars/def.png") < 0 && (
            <IonButton
              expand="block"
              color="danger"
              onClick={() => deleteHandler()}
            >
              <IonIcon icon={trashBinOutline} slot="start" />
              Profilbild löschen
            </IonButton>
          )}
        </p>
      </IonContent>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    myId: state.user.user_id,
    avatar: state.user.avatar,
  }),
  mapDispatchToProps: { setAvatar },
  component: ChangeAvatar,
});
