import { fetchLogout, getUserData } from "../chatApi";
import { ActionType } from "../types";
import { UserState } from "./user.state";

export const loadUserData = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUserData();
  dispatch(setData(data));
  dispatch(setLoading(false));
};
export const userCallbackFunctions =
  (result: string, history: any) => async (dispatch: React.Dispatch<any>) => {
    if (result === "login") {
      fetchLogout();
      dispatch(setLogout());
    }
    if (result === "blacklist") {
      history.push("/blacklist/block");
      dispatch(setLogout());
    }
    if (result === "kick") {
      history.push("/blacklist/kick");
      dispatch(setLogout());
    }
    if (result === "spam") {
      history.push("/blacklist/spam");
      dispatch(setLogout());
    }
    if (result === "home") {
      history.push("/");
      dispatch(setLogout());
    }
  };

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-user-loading",
    isLoading,
  } as const);

export const setData = (data: Partial<UserState>) =>
  ({
    type: "set-user-data",
    data,
  } as const);

export const systemMessage = (sysmess: boolean) =>
  ({ type: "sys-message", sysmess } as const);
export const changePvLock = (pvlock: string) =>
  ({ type: "pv-lock", pvlock } as const);
export const changeStatus = (status: string) =>
  ({ type: "change-status", status } as const);
export const setAvatar = (avatar: string) =>
  ({ type: "set-avatar", avatar } as const);
export const logout = () => async (dispatch: React.Dispatch<any>) => {
  fetchLogout();
  dispatch(setLogout());
};
export const setLogout = () => ({ type: "logout" } as const);

export const setSplit = (split: boolean) =>
  ({
    type: "set-split",
    split,
  } as const);
export const setDarkMode = (darkMode: boolean) =>
  ({
    type: "set-dark-mode",
    darkMode,
  } as const);
export const setDailyPrize = (dailyPrize: boolean, days: string) =>
  ({ type: "set-daily-prize", dailyPrize, days } as const);

export type UserActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setData>
  | ActionType<typeof changePvLock>
  | ActionType<typeof systemMessage>
  | ActionType<typeof changeStatus>
  | ActionType<typeof setAvatar>
  | ActionType<typeof setLogout>
  | ActionType<typeof setSplit>
  | ActionType<typeof setDailyPrize>
  | ActionType<typeof setDarkMode>;

/*export const logoutUser = () => async (dispatch: React.Dispatch<any>) => {
  await setIsLoggedInData(false);
  dispatch(setUsername());
};

export const setIsLoggedIn =
  (loggedIn: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setIsLoggedInData(loggedIn);
    return {
      type: "set-is-loggedin",
      loggedIn,
    } as const;
  };

export const setUsername =
  (username?: string) => async (dispatch: React.Dispatch<any>) => {
    await setUsernameData(username);
    return {
      type: "set-username",
      username,
    } as const;
  };

export const setHasSeenTutorial =
  (hasSeenTutorial: boolean) => async (dispatch: React.Dispatch<any>) => {
    await setHasSeenTutorialData(hasSeenTutorial);
    return {
      type: "set-has-seen-tutorial",
      hasSeenTutorial,
    } as const;
  };*/
