import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonToolbar,
  IonInput,
  IonHeader,
  IonPage,
  IonBackButton,
  useIonModal,
  IonTitle,
} from "@ionic/react";
import React, { useRef, useEffect, useState } from "react";
import ChatBubble from "../components/ChatBubble";
import Smileys from "../sections/Smileys";
import { paperPlaneOutline, happyOutline, cameraOutline } from "ionicons/icons";

import { connect } from "../data/connect";
import {
  getOldMessages,
  getUserInfo,
  sendMessage,
  setCurrentChat,
  setRead,
} from "../data/messages/messages.actions";
import { userCallbackFunctions as UCF } from "../data/user/user.actions";
import { Message } from "../models/Message";
import { Recent } from "../models/Recent";
import { getUserOnlineById } from "../data/selectors";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import UploadPicture from "../sections/UploadPicture";
import { getMessagesOnline } from "../data/chatApi";

interface StateProps {
  room: string;
  myId: string;
  priv: string;
  last_id: string;
  privatMessages: any;
  chat: Recent;
  onlinelist: any;
  smileys: object;
  unread: number;
}

interface DispatchProps {
  sendMessage: typeof sendMessage;
  getOldMessages: typeof getOldMessages;
  getUserInfo: typeof getUserInfo;
  setCurrentChat: typeof setCurrentChat;
  setRead: typeof setRead;
  UCF: typeof UCF;
}

interface PrivatProps extends RouteComponentProps, StateProps, DispatchProps {}
const Privat: React.FC<PrivatProps> = ({
  room,
  myId,
  priv,
  last_id,
  chat,
  smileys,
  privatMessages,
  getOldMessages,
  getUserInfo,
  setCurrentChat,
  sendMessage,
  setRead,
  history,
  unread,
  UCF,
}) => {
  const contentRef = useRef<any>(null);
  const [sending, setSending] = useState(false);
  const [text, setText] = useState("");
  //const [chatInfo, setChat] = useState<any>({ name: "unknown" });
  const { id } = useParams<{ id: string }>();
  //const ios = isPlatform("ios");

  // eslint-disable-next-line
  const messages: Message[] = privatMessages[id] || [];

  const callback = (res: string) => {
    UCF(res, history);
  };

  useEffect(() => {
    if (contentRef.current) contentRef.current.scrollToBottom(0);
    if (unread > 0) setRead(id as string);
    // eslint-disable-next-line
  }, [messages, id]);

  useEffect(() => {
    if (priv !== "gast") getOldMessages(id);
    setCurrentChat(id);
    setSeen(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (chat.user === "unknown") getUserInfo(id);
    // eslint-disable-next-line
  }, [id, chat]);

  const setSeen = (id: any) => {
    getMessagesOnline({ seenu: id });
  };

  const sendHandler = (e: any) => {
    if (text === "") return false;
    setSending(true);
    sendMessage(
      {
        room: room,
        message: "/window:" + text,
        privat: id,
        last_id,
        screenw: window.innerWidth,
        screenh: window.innerHeight,
      },
      myId,
      callback
    );
    setText("");
    setSending(false);
    setSeen(id);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      sendHandler(event);
    }
  };
  const paste = (str: string) => {
    setText(text + "" + str);
  };
  const selectSmiley = (code: string) => {
    setText(text + "" + code);
    dismiss();
  };
  const handleDismiss = () => {
    dismiss();
  };
  const [present, dismiss] = useIonModal(Smileys, {
    smileys: smileys,
    choose: selectSmiley,
    onDismiss: handleDismiss,
  });
  const setPhoto = (code: string) => {
    setText(text + "" + code);
    upClose();
  };
  const uploadDismiss = () => {
    upClose();
  };
  const [upload, upClose] = useIonModal(UploadPicture, {
    setPhoto,
    onDismiss: uploadDismiss,
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/chat/room" />
          </IonButtons>
          <IonTitle
            onClick={(e) => {
              e.preventDefault();
              history.push("/chat/profile/" + id);
            }}
          >
            {chat.user}
          </IonTitle>
          {/*<IonItem
            lines="none"
            button
            detail={false}
            className={ios ? "custom-item-ios" : "custom-item"}
            onClick={(e) => {
              e.preventDefault();
              history.push("/chat/profile/" + id);
            }}
          >
            <IonAvatar
              slot="start"
              style={{ width: "36px", height: "36px", marginRight: "10px" }}
            >
              <img
                src={"https://www.cleefchat.de/" + chat.user_avatar}
                alt={chat.user}
              />
            </IonAvatar>
            <IonLabel style={{ marginTop: 0, marginBottom: 0 }} slot="start">
              <h2 style={{ marginBottom: "-1px" }}>{chat.user}</h2>
              <p>online</p>
            </IonLabel>
          </IonItem>*/}
          <IonButtons slot="end">
            <IonButton
              onClick={(e) => {
                e.preventDefault();
                history.push("/chat/profile/" + id);
              }}
            >
              <img
                src={"https://www.cleefchat.de/" + chat.user_avatar}
                width={32}
                height={32}
                style={{ borderRadius: 50 }}
                alt={chat.user}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen ref={contentRef} scrollEvents>
        <ChatBubble messages={messages} pv={true} paste={paste} />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              color="primary"
              shape="round"
              slot="start"
              onClick={() => {
                present();
              }}
            >
              <IonIcon icon={happyOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonButtons>
            <IonInput
              value={text}
              placeholder="Message"
              disabled={sending}
              onIonChange={(e) => setText(e.detail.value || "")}
              enterkeyhint="send"
              onKeyUp={handleKeyPress}
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              color="primary"
              shape="round"
              slot="start"
              onClick={() => upload()}
            >
              <IonIcon icon={cameraOutline} slot="icon-only" />
            </IonButton>
            {text.length > 0 && (
              <IonButton
                color="primary"
                shape="round"
                slot="end"
                onClick={sendHandler}
              >
                <IonIcon icon={paperPlaneOutline} slot="icon-only" />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};
export default connect({
  mapStateToProps: (state, OwnProps) => ({
    room: state.messages.room,
    myId: state.user.user_id,
    priv: state.user.user_priv,
    last_id: state.messages.last_id,
    chat:
      state.messages.privatChats[OwnProps.match.params.id] ||
      state.messages.userInfo[OwnProps.match.params.id] ||
      getUserOnlineById(state.messages.onlinelist, OwnProps.match.params.id),
    privatMessages: state.messages.privatMessages,
    smileys: state.messages.smileys,
    unread: (state.messages.unread[OwnProps.match.params.id] || []).length,
  }),
  mapDispatchToProps: {
    getOldMessages,
    getUserInfo,
    sendMessage,
    setRead,
    setCurrentChat,
    UCF,
  },
  component: withRouter(Privat),
});
