import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonList,
  IonItem,
  useIonAlert,
  IonLabel,
  IonGrid,
  IonCol,
} from "@ionic/react";
import { fetchBlockUser } from "../data/chatApi";

interface ChangePwProps {
  onDismiss: () => void;
}

const BlockedUser: React.FC<ChangePwProps> = ({ onDismiss }) => {
  const [present] = useIonAlert();
  const [data, setData] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    fetchBlockUser({ showlist: "1", json: "1" }).then((res: any) => {
      if (res.startsWith("[")) {
        const json = JSON.parse(res);
        console.log(json);
        setData(json);
      }
    });
  };

  const deleteHandler = (user_id: string) => {
    present({
      header: "löschen?!",
      message: "bist du sicher ?",
      buttons: [
        { text: "Nein", role: "cancel" },
        {
          text: "Ja, Ich bin",
          handler: () => {
            fetchBlockUser({ block_all: user_id, blocktype: "0" }).then(
              (res: any) => {
                setData(data.filter((item: any) => item.user_id !== user_id));
              }
            );
          },
        },
      ],
    });
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Blocked Users</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {data.length > 0 ? (
          <IonList>
            <IonItem>
              <IonGrid>
                <IonCol>Name</IonCol>
                <IonCol>Datum</IonCol>
                <IonCol>Status</IonCol>
              </IonGrid>
            </IonItem>
            {data.map((item: any) => (
              <IonItem key={item.user_id}>
                <IonLabel>
                  <IonGrid>
                    <IonCol>{item.name}</IonCol>
                    <IonCol>{item.time}</IonCol>
                    <IonCol>{item.status}</IonCol>
                  </IonGrid>
                </IonLabel>
                <IonButton
                  fill="outline"
                  color="danger"
                  slot="end"
                  onClick={() => deleteHandler(item.user_id)}
                >
                  löschen
                </IonButton>
              </IonItem>
            ))}
          </IonList>
        ) : (
          <p className="ion-paddibg" style={{ textAlign: "center" }}>
            Kein Benutzer ist in deiner BlackList.
          </p>
        )}
      </IonContent>
    </>
  );
};

export default BlockedUser;
