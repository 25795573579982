import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonSpinner,
  IonButtons,
  IonBackButton,
  IonPage,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewWillEnter,
  IonButton,
  IonIcon,
  useIonModal,
  useIonAlert,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { fetchPinnwand } from "../data/chatApi";
import PinnwandAdd from "../sections/PinnwandAdd";

//interface PinnwandProps {}

const Pinnwand: React.FC = () => {
  const [present] = useIonAlert();
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<any>([]);
  const loadData = (reload: boolean = false) => {
    setInfiniteDisabled(true);
    //setLoading(true);
    fetchPinnwand({ json: "1", seite: page }).then((res: any) => {
      setLoading(false);
      if (res.startsWith("[")) {
        const json = JSON.parse(res);
        if (reload) setData([...json]);
        else {
          setData([...data, ...json]);
          setPage(page + 1);
        }
      } else if (res === "0") {
        if (page === 1) setErr("the pinnwand is empty!");
        else setInfiniteDisabled(false);
      } else setErr(res);
    });
  };
  const deleteHandler = (id: string) => {
    present({
      header: "löschen?!",
      message: "bist du sicher ?",
      buttons: [
        { text: "Nein", role: "cancel" },
        {
          text: "Ja, Ich bin",
          handler: () => {
            fetchPinnwand({ deletepinn: id });
            setData(data.filter((item: any) => item.id !== id));
          },
        },
      ],
      //onDidDismiss: () => {},
    });
  };
  /*useEffect(() => {
    loadData();
  }, []);*/
  useIonViewWillEnter(() => {
    loadData();
  });

  const addDismiss = (reload: boolean = false) => {
    closeAdd();
    if (reload) {
      setPage(1);
      loadData(true);
    }
  };
  const [addWin, closeAdd] = useIonModal(PinnwandAdd, {
    onDismiss: addDismiss,
  });
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/chat/room" />
          </IonButtons>
          <IonTitle>Pinnwand</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => addWin()}>
              <IonIcon icon={addCircleOutline} slot="icon-only" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loading && <IonSpinner />}
        {!loading && err !== "" && <p>{err}</p>}
        {!loading &&
          !!data.length &&
          data.map((item: any) => (
            <IonCard key={item.id}>
              <IonCardHeader>
                <IonCardSubtitle>{item.date}</IonCardSubtitle>
                <IonCardTitle>{item.username}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                {item.text.split("\n").map((item: any, key: number) => {
                  return (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  );
                })}
                {item.delete && (
                  <IonButton
                    size="small"
                    color="danger"
                    onClick={() => deleteHandler(item.id)}
                  >
                    löschen
                  </IonButton>
                )}
              </IonCardContent>
            </IonCard>
          ))}
        <IonInfiniteScroll
          onIonInfinite={() => {
            loadData();
          }}
          threshold="100px"
          disabled={isInfiniteDisabled}
        >
          <IonInfiniteScrollContent
            loadingSpinner="bubbles"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Pinnwand;
