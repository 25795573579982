import { Storage } from "@capacitor/storage";

const loginUrl = "https://www.cleefchat.de/?CheckUserName";
const creditsUrl = "https://www.cleefchat.de/?GetSessionsForAndroid";
const onlinelistUrl = "https://www.cleefchat.de/?ReloaderUserOnline";
const messagesUrl = "https://www.cleefchat.de/?ReloaderMessages";
const dataUrl = "https://www.cleefchat.de/?GetUserDataForAndroid";
const blockUser = "https://www.cleefchat.de/?BlockUser";
const abuseUrl = "https://www.cleefchat.de/?Abuse";
const kickUserUrl = "https://www.cleefchat.de/?Insert2Blacklist";
const notificationsUrl = "https://www.cleefchat.de/?Notification";
const pinnwandUrl = "https://www.cleefchat.de/?Pinnwand";
const changeStatusUrl = "https://www.cleefchat.de/?ChangeStatus";
const blockUserUrl = "https://www.cleefchat.de/?BlockUser";
const changePwUrl = "https://www.cleefchat.de/?ChangePw";
const logoutUrl = "https://www.cleefchat.de/?Logout";
const friendsUrl = "https://www.cleefchat.de/?FriendsList";
const deleteFriendUrl = "https://www.cleefchat.de/?DeleteFriendship";
const acceptFriendUrl = "https://www.cleefchat.de/?AcceptFriendRequest";
const friendReqUrl = "https://www.cleefchat.de/?FriendRequestSend";
const topUsersUrl = "https://www.cleefchat.de/?TopTimeStay";
const userStatisticsUrl = "https://www.cleefchat.de/?UserStatisticShow";
const uploadUrl = "https://www.cleefchat.de/?FileSend";
const checkRoomPwUrl = "https://www.cleefchat.de/?CheckRoomPw";
const changeAvUrl = "https://www.cleefchat.de/?ChangeUserAvatar";
const complaintUrl = "https://www.cleefchat.de/?Complaint";
const dailyPrizeUrl = "https://www.cleefchat.de/?DailyPrizes";
const quizUrl = "https://www.cleefchat.de/?QuizJson";
const alteQuizUrl = "https://www.cleefchat.de/?QuizQuestionsAjax";
const userInfoUrl = "https://www.cleefchat.de/?GetUserData";

const LOGIN_DATA = "loginData";

export const setUserData = async (data?: any) => {
  if (!data) {
    await Storage.remove({ key: LOGIN_DATA });
  } else {
    await Storage.set({ key: LOGIN_DATA, value: JSON.stringify(data) });
  }
};

export const getUserData = async () => {
  const response = await fetch(dataUrl, {
    credentials: "include",
  });
  const webData = await response.json();
  if (webData.user_id != null) {
    return {
      isLoggedin: true,
      username: webData.username,
      user_id: webData.user_id,
      gender: webData.user_sex,
      user_priv: webData.user_priv,
      avatar: webData.avatar,
      current_status: webData.current_status,
      sys_messages: webData.sys_messages,
      showtopradio: webData.showtopradio,
      lockprivat: webData.lockprivat,
      user_privs: webData.user_privs,
      mod_privs: webData.mod_privs,
      user_all_privs: webData.user_all_privs,
      perms_read_history: webData.perms_read_history,
      perms_kick_user: webData.perms_kick_user,
      perms_abuse: webData.perms_abuse,
      perms_invisiblesee: webData.perms_invisiblesee,
      perms_pinnwand: webData.perms_pinnwand,
      perms_admin_link: webData.perms_admin_link,
      status: webData.status,
    };
  }
  console.log("Lets go");
  const { value } = await Storage.get({ key: LOGIN_DATA });
  console.log(value);
  const out = JSON.parse(value || "{}");
  // eslint-disable-next-line
  if (out.user_id != "") {
    console.log(out);
    login({
      username: out.username,
      gender: out.gender,
      pw: out.password,
      status_invisible: out.invisible,
    })
      .then((res) => {
        return {
          isLoggedin: true,
          username: out.username,
          user_id: out.user_id,
          gender: out.user_sex,
          user_priv: out.user_priv,
        };
      })
      .catch((err) => {
        return {
          isLoggedin: false,
        };
      });
  }
  return {
    isLoggedin: false,
  };
};

export const login = (qq: query) => {
  return new Promise((resolve, reject) => {
    getCredits()
      .then((res: any) => {
        //let quo = qq;
        qq[res.set_check] = res.aktuell_date_u;
        fetch(loginUrl, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: serialize(qq),
        })
          .then((res) => res.text())
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
export const getCredits: any = () => {
  return new Promise((resolve, reject) => {
    fetch(creditsUrl, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.aktuell_date_u) resolve(result);
        else reject("error: cookie didn't set!");
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMessagesOnline: any = (qq: query) => {
  qq.screenw = window.innerWidth;
  qq.screenh = window.innerHeight;
  qq.ismobile = "1";
  return new Promise((resolve, reject) => {
    fetch(messagesUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const getOnlineUsers: any = () => {
  return new Promise((resolve, reject) => {
    fetch(onlinelistUrl, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.text())
      .then((result) => {
        if (result.startsWith("{")) {
          const json = JSON.parse(result);
          resolve(json);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const sendBlockUser: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(blockUser, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const getAbuse: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(abuseUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const fetchKickUser: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(kickUserUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const getNotifications: any = () => {
  return new Promise((resolve, reject) => {
    fetch(`${notificationsUrl}&json=1`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const setNotifications: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(notificationsUrl, {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchPinnwand: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(pinnwandUrl, {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchStatus: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(changeStatusUrl, {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchBlockUser: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(blockUserUrl, {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchChangePw: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(changePwUrl, {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const fetchLogout: any = () => {
  return new Promise((resolve, reject) => {
    fetch(logoutUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "allow=1",
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchFriends: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(friendsUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const deleteFriendship: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(deleteFriendUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const acceptFriendship: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(acceptFriendUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const friendRequest: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(friendReqUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const topUsers: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(topUsersUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const getStatistics: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(userStatisticsUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const CheckRoomPw: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(checkRoomPwUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchUpload: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(uploadUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const uploadFile: any = (file: any) => {
  const formData = new FormData();
  formData.append("submit", "yes");
  formData.append("file", file);
  return new Promise((resolve, reject) => {
    fetch(uploadUrl, {
      method: "POST",
      credentials: "include",
      body: formData,
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const fetchAvatar: any = (qq: query) => {
  return new Promise((resolve, reject) => {
    fetch(changeAvUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(qq),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const changeAvatar: any = (file: any) => {
  const formData = new FormData();
  formData.append("avatar", file);
  return new Promise((resolve, reject) => {
    fetch(changeAvUrl, {
      method: "POST",
      credentials: "include",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchComplaint: any = (text: string) => {
  return new Promise((resolve, reject) => {
    fetch(complaintUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize({ text }),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchDailyPrize: any = (query: query = {}) => {
  return new Promise((resolve, reject) => {
    fetch(dailyPrizeUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(query),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchQuiz: any = (query: query = {}) => {
  return new Promise((resolve, reject) => {
    fetch(quizUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(query),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
export const fetchAlteQuiz: any = (query: query = {}) => {
  return new Promise((resolve, reject) => {
    fetch(alteQuizUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(query),
    })
      .then((res) => res.text())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const fetchUserInfo: any = (query: query = {}) => {
  return new Promise((resolve, reject) => {
    fetch(userInfoUrl, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: serialize(query),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

type query = { [k: string]: any };
const serialize = function (obj: query) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
