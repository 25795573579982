import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
} from "@ionic/react";

interface QRProps {
  onDismiss: () => void;
}

const QuizRating: React.FC<QRProps> = ({ onDismiss }) => {
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Quiz-Rating</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <table>
          <tr>
            <th>Platz</th>
            <th>Benutzer</th>
            <th>Score</th>
            <th>Antwoten</th>
            <th>richtig</th>
            <th>falsch</th>
            <th>QuizMaster</th>
          </tr>
        </table>
      </IonContent>
    </>
  );
};

export default QuizRating;
