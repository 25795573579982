import {
  IonContent,
  IonToolbar,
  IonHeader,
  IonMenu,
  IonList,
  IonListHeader,
  IonMenuToggle,
  IonSearchbar,
  IonTitle,
  IonButtons,
} from "@ionic/react";
import React, { useMemo, useState } from "react";
import { connect } from "../data/connect";
import "./Menu.css";
import { Recent } from "../models/Recent";
import Room from "./Room";
import OnlineList from "./OnlineList";
import ChatItem from "./ChatItem";

interface StateProps {
  onlinelist: {
    userOnline: any;
    all_empty_rooms: any;
  };
  privatChats: { [k: string]: Recent };
}

interface DispatchProps {}

interface MenuProps extends StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({ onlinelist, privatChats }) => {
  const [searchText, setSearchText] = useState("");

  const pvs = useMemo(() => {
    let final = Object.values(privatChats);
    final.sort((a: any, b: any) =>
      parseInt(a.time) > parseInt(b.time)
        ? -1
        : parseInt(b.time) > parseInt(a.time)
        ? 1
        : 0
    );
    return final;
  }, [privatChats]);

  const onlineUsers = useMemo(() => {
    if (searchText === "" || onlinelist.userOnline.length === 1)
      return onlinelist.userOnline;
    return onlinelist.userOnline.filter(
      (item: any) =>
        item.user.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
  }, [onlinelist, searchText]);

  return (
    <IonMenu type="overlay" disabled={false} contentId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Online Users</IonTitle>
          <IonButtons slot="end">
            <IonButtons> </IonButtons>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="menu-content">
        <IonSearchbar
          value={searchText}
          onIonClear={() => setSearchText("")}
          onIonChange={(e) => setSearchText(e.detail.value || "")}
          placeholder={`Suche zwischen ${onlinelist.userOnline.length} Online Benutzer...`}
          showClearButton="always"
        ></IonSearchbar>
        <IonList>
          {pvs.length > 0 && <IonListHeader>Recent Conversation</IonListHeader>}
          {pvs.map((item: any) => (
            <IonMenuToggle key={"c" + item.user_id} auto-hide="false">
              <ChatItem user={item} />
            </IonMenuToggle>
          ))}
          <IonListHeader>OnlineUsers</IonListHeader>
          <OnlineList key="ol" onlinelist={onlineUsers} />

          {!!onlinelist.all_empty_rooms.length &&
            onlinelist.all_empty_rooms.map((r: any) => {
              return (
                <Room
                  key={"r" + r.room_id}
                  room={r.room}
                  room_id={r.room_id}
                  room_allowed={r.room_allowed}
                />
              );
            })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    onlinelist: state.messages.onlinelist,
    privatChats: state.messages.privatChats,
  }),
  mapDispatchToProps: {},
  component: Menu,
});
