import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonPage,
  useIonAlert,
  IonList,
  IonSearchbar,
  IonItem,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonAvatar,
  IonIcon,
  useIonActionSheet,
  IonBadge,
} from "@ionic/react";
import { female, male, maleFemale } from "ionicons/icons";
import {
  acceptFriendship,
  deleteFriendship,
  fetchFriends,
} from "../data/chatApi";
import { RouteComponentProps, withRouter } from "react-router-dom";

//interface FriendsProps {}

const Friends: React.FC<RouteComponentProps> = ({ history }) => {
  const [ionAlert] = useIonAlert();
  const [present] = useIonActionSheet(); //, dismiss
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const getSearchedResult = (list: any[]) => {
    if (search === "" || list.length === 1) return list;
    return list.filter(
      (item) => item.username.toLowerCase().indexOf(search.toLowerCase()) > -1
    );
  };
  const loadData = () => {
    fetchFriends({ json: "1" }).then((res: any) => {
      if (res.startsWith("[")) {
        const json = JSON.parse(res);
        setData(json);
      }
    });
  };

  const getButtons = (item: any) => {
    let buttons: object[] = [];
    buttons.push({
      text: "private Nachricht",
      handler: () => {
        history.push("/chat/" + item.user_id);
      },
    });
    if (item.status === "accept") {
      buttons.push({
        text: "akzeptieren",
        handler: () => {
          acceptHandler(item.user_id);
        },
      });
      buttons.push({
        text: "ablehnen",
        role: "destructive",
        handler: () => {
          deleteHandler(item.user_id, item.username);
        },
      });
    } else
      buttons.push({
        role: "destructive",
        text:
          item.status === "waiting"
            ? "Freundschaftsanfrage abbrechen"
            : "löschen",
        handler: () => {
          deleteHandler(item.user_id, item.username);
        },
      });
    buttons.push({ text: "Cancel", role: "cancel" });
    return {
      buttons,
      header: item.username,
    };
  };
  const acceptHandler = (id: string) => {
    acceptFriendship({ userid: id }).then((res: any) => {
      loadData();
    });
  };
  const deleteHandler = (id: string, name: string) => {
    ionAlert({
      message: `willst du die freundschaft mit ${name} wirklich löschen?`,
      buttons: [
        { text: "Nein", role: "cancel" },
        {
          text: "Ja, Ich bin",
          handler: () => {
            deleteFriendship({ userid: id }).then((res: any) => {
              loadData();
            });
          },
        },
      ],
    });
  };
  useEffect(() => {
    loadData();
  }, []);
  const genIcon = (gen: string) => {
    let icon = maleFemale;
    let color = "dark";
    if (gen === "f") icon = female;
    if (gen === "m") icon = male;
    if (gen === "f") color = "danger";
    if (gen === "m") color = "primary";
    return <IonIcon icon={icon} color={color} slot="start" />;
  };
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Friends</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            placeholder="Suche..."
            value={search}
            onIonClear={() => setSearch("")}
            onIonChange={(e) => setSearch(e.detail.value || "")}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true}>
        <IonList>
          {!!data.length &&
            getSearchedResult(data).map((item: any) => (
              <IonItemSliding key={item.user_id}>
                <IonItem button onClick={() => present(getButtons(item))}>
                  <IonAvatar slot="start">
                    <img
                      src={"https://www.cleefchat.de/" + item.avatar}
                      alt={item.username}
                    />
                  </IonAvatar>
                  <IonLabel>
                    <h2>
                      {genIcon(item.user_sex)}{" "}
                      {item.user_priv !== "gast" && (
                        <>
                          <img
                            src={`https://www.cleefchat.de/img/${item.user_priv}_i.png`}
                            alt={item.user_priv}
                            width={16}
                            height={16}
                            style={{ verticalAlign: "middle" }}
                          />{" "}
                        </>
                      )}
                      {item.username}
                    </h2>
                    <p>
                      {item.status === "accept" && "Zum Akzeptieren schieben"}
                      {item.status === "waiting" && "wartet auf Genehmigung"}
                    </p>
                  </IonLabel>
                  {item.online && <IonBadge color="warning">online</IonBadge>}
                </IonItem>
                <IonItemOptions side="end">
                  {item.status === "accept" && (
                    <>
                      <IonItemOption
                        color="success"
                        expandable
                        onClick={() => acceptHandler(item.user_id)}
                      >
                        akzeptieren
                      </IonItemOption>
                      <IonItemOption
                        color="danger"
                        expandable
                        onClick={() =>
                          deleteHandler(item.user_id, item.username)
                        }
                      >
                        ablehnen
                      </IonItemOption>
                    </>
                  )}
                  {item.status === "waiting" && (
                    <IonItemOption
                      color="danger"
                      expandable
                      onClick={() => deleteHandler(item.user_id, item.username)}
                    >
                      abbrechen
                    </IonItemOption>
                  )}
                  {item.status === "active" && (
                    <IonItemOption
                      color="danger"
                      expandable
                      onClick={() => deleteHandler(item.user_id, item.username)}
                    >
                      löschen
                    </IonItemOption>
                  )}
                </IonItemOptions>
              </IonItemSliding>
            ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Friends);
