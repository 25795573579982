import { MessagesActions } from "./messages.actions";
import { ConfState } from "./conf.state";
import { getChatInfo } from "../selectors";
import { Message } from "../../models/Message";

export const messagesReducer = (
  state: ConfState,
  action: MessagesActions
): ConfState => {
  switch (action.type) {
    case "push-messages": {
      let messages = {};
      let chats = {};
      let privat = {};
      let unread = {};
      action.messages.forEach((item: Message) => {
        if (
          parseInt(item.privat) < 2 ||
          (item.user_id == "1" && !item.pvinwindow && state.currentChat == "")
        ) {
          messages = { ...messages, [item.id]: item };

          unread = {
            ...unread,
            ["room"]: [
              ...(state.unread["room"] || []),
              ...Object.keys(messages),
            ].filter((value, index, self) => self.indexOf(value) === index),
          };
        } else {
          if (
            item.user_id == "1" &&
            !item.pvinwindow &&
            state.currentChat != ""
          ) {
            privat = {
              ...privat,
              [state.currentChat]: {
                ...(state.privatMessages[state.currentChat] || {}),
                [item.id]: item,
              },
            };
          } else {
            let contact =
              item.user_id == action.myself ? item.privat : item.user_id;
            privat = {
              ...privat,
              [contact]: {
                ...(state.privatMessages[contact] || {}),
                [item.id]: item,
              },
            };
            chats = {
              ...chats,
              [contact]: {
                ...getChatInfo(state, item, contact),
                sender: item.user_id,
                message: item.message,
                time: item.time,
              },
            };

            unread = {
              ...unread,
              [contact]: [
                ...(state.unread[contact] || []),
                ...Object.keys(privat),
              ].filter((value, index, self) => self.indexOf(value) === index),
            };
          }
        }
      });
      let final = { ...state };
      if (Object.keys(messages).length)
        final.messages = { ...state.messages, ...messages };
      if (Object.keys(privat).length)
        final.privatMessages = { ...state.privatMessages, ...privat };
      if (Object.keys(chats).length)
        final.privatChats = { ...state.privatChats, ...chats };
      if (Object.keys(unread).length)
        final.unread = { ...state.unread, ...unread };
      return {
        ...final,
        last_id: action.last_id,
      };
    }
    case "set-recent":
      return {
        ...state,
        privatChats: { ...state.privatChats, ...action.data },
      };
    case "set-pv-messages": {
      if (typeof state.privatMessages[action.user_id] == "object") {
        state.privatMessages[action.user_id] = {
          ...action.pv,
          ...state.privatMessages[action.user_id],
        };
      } else state.privatMessages[action.user_id] = { ...action.pv };
      return { ...state, privatMessages: { ...state.privatMessages } };
    }
    case "set-user-info":
      return {
        ...state,
        userInfo: { ...state.userInfo, [action.user_id]: action.data },
      };
    case "update-onlinelist":
      return { ...state, onlinelist: action.onlinelist };
    case "change-room":
      return {
        ...state,
        room: action.room,
        roomName: action.roomName,
        messages: {},
      };
    case "set-smileys":
      return { ...state, smileys: action.smileys };
    case "set-notification":
      return { ...state, notification: action.notification };
    case "set-coins":
      return { ...state, coins: action.coins };
    case "set-read": {
      if (state.unread.hasOwnProperty(action.id)) state.unread[action.id] = [];
      return { ...state, unread: { ...state.unread } };
    }
    case "set-current":
      return { ...state, currentChat: action.id };
  }

  return state;
};
