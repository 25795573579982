import { ActionType } from "../types";
import { Message } from "../../models/Message";
import { getMessagesOnline, getOnlineUsers, fetchUserInfo } from "../chatApi";

export const reloaderUserOnline =
  (myId: string, room: string) => async (dispatch: React.Dispatch<any>) => {
    const result = await getOnlineUsers();
    if (result) {
      dispatch(updateOnlinelist(result));
      result.userOnline.forEach((ol: any) => {
        if (ol.user_id == myId) {
          if (ol.isblock == "1" && room != "1968")
            dispatch(changeRoom("1968", "Schluss mit lustig"));
          if (ol.isblock == "0" && room == "1968")
            dispatch(changeRoom("1", "Lobby"));
        }
      });
    }
  };
export const reloaderMessages =
  (room: string, last_id: string, myId: string, ucf: any) =>
  async (dispatch: React.Dispatch<any>) => {
    const result = await getMessagesOnline({ room, last_id: last_id });
    updateMessages(result, myId, ucf, dispatch);
  };
export const sendMessage =
  (query: object, myId: string, ucf: any) =>
  async (dispatch: React.Dispatch<any>) => {
    const result = await getMessagesOnline({ ...query });
    updateMessages(result, myId, ucf, dispatch);
  };
export const changeRoomMessage =
  (room: string, roomName: string, last_id: string, myId: string, ucf: any) =>
  async (dispatch: React.Dispatch<any>) => {
    dispatch(changeRoom(room, roomName));
    const result = await getMessagesOnline({
      room,
      message: "betritt den Raum ",
      last_id: last_id,
      sysmess: 1,
      privat: 0,
      roomchange: "true",
    });
    updateMessages(result, myId, ucf, dispatch);
  };
export const getRecentChats = () => async (dispatch: React.Dispatch<any>) => {
  const result = await getMessagesOnline({
    getchats: "1",
  });
  const json = await JSON.parse(result);
  if (json.result && json.data) dispatch(setRecent(json.data));
};
export const setRecent = (data: any) => {
  return {
    type: "set-recent",
    data,
  } as const;
};
export const getOldMessages =
  (user_id: string) => async (dispatch: React.Dispatch<any>) => {
    const result = await getMessagesOnline({
      getmessages: user_id,
    });
    const json = await JSON.parse(result);
    if (json.result && json.data.length > 0)
      dispatch(setPvMessages(user_id, json.data));
  };
export const getUserInfo =
  (user_id: string) => async (dispatch: React.Dispatch<any>) => {
    const json = await fetchUserInfo({
      id: user_id,
    });
    //const json = await JSON.parse(result);
    if (json) {
      const data = {
        user_id: json.user_id,
        user: json.username,
        user_priv: json.user_priv,
        user_avatar: json.avatar,
        user_sex: json.user_sex,
        sender: json.user_id,
        message: "",
        time: "",
      };
      dispatch(setUserInfo(user_id, data));
    }
  };
export const setUserInfo = (user_id: any, data: any) =>
  ({
    type: "set-user-info",
    data,
    user_id,
  } as const);
export const setPvMessages = (user_id: any, result: any) => {
  let final = {};
  result.forEach((item: any) => (final = { ...final, [item.id]: { ...item } }));
  return {
    type: "set-pv-messages",
    pv: final,
    user_id,
  };
};
export const updateMessages = (
  result: any,
  myId: string,
  ucf: any,
  dispatch: React.Dispatch<any>
) => {
  if (["login", "blacklist", "kick", "spam", "home", "F5"].includes(result))
    ucf(result);
  if (result.startsWith("{")) {
    const json = JSON.parse(result);
    if (json.data) dispatch(pushMessages(json.data, myId));
    if (json.news) {
      dispatch(setNotifications(json.news.notification));
      dispatch(setCoins(json.news.coins));
    }
  }
};
export const pushMessages = (messages: Message[], myself: string) => {
  const last_id = messages[messages.length - 1].id;
  return {
    type: "push-messages",
    messages,
    myself,
    last_id,
  };
};
export const updateOnlinelist = (json: object) =>
  ({
    type: "update-onlinelist",
    onlinelist: json,
  } as const);
export const changeRoom = (room: string, roomName: string) =>
  ({
    type: "change-room",
    room,
    roomName,
  } as const);
export const setSmileys = (smileys: object) =>
  ({
    type: "set-smileys",
    smileys,
  } as const);
export const setNotifications = (notification: string) =>
  ({
    type: "set-notification",
    notification,
  } as const);
export const setCoins = (coins: string) =>
  ({
    type: "set-coins",
    coins,
  } as const);
export const setRead = (id: string) =>
  ({
    type: "set-read",
    id,
  } as const);
export const setCurrentChat = (id: string) =>
  ({
    type: "set-current",
    id,
  } as const);

export const setLoading = (isLoading: boolean) =>
  ({
    type: "set-conf-loading",
    isLoading,
  } as const);

export type MessagesActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof updateOnlinelist>
  | ActionType<typeof changeRoom>
  | ActionType<typeof reloaderMessages>
  | ActionType<typeof reloaderUserOnline>
  | ActionType<typeof updateMessages>
  | ActionType<typeof getOldMessages>
  | ActionType<typeof getUserInfo>
  | ActionType<typeof setUserInfo>
  | ActionType<typeof setPvMessages>
  | ActionType<typeof getRecentChats>
  | ActionType<typeof setRecent>
  | ActionType<typeof setNotifications>
  | ActionType<typeof setCoins>
  | ActionType<typeof setRead>
  | ActionType<typeof setCurrentChat>
  | ActionType<typeof pushMessages>
  | ActionType<typeof setSmileys>;
