import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonTextarea,
  useIonAlert,
} from "@ionic/react";
import { fetchPinnwand } from "../data/chatApi";

interface StateProps {
  onDismiss: any;
}

//interface DispatchProps {}
//interface ChangeStatusProps extends StateProps, DispatchProps {}
const PinnwandAdd: React.FC<StateProps> = ({ onDismiss }) => {
  const [present] = useIonAlert();
  const [text, setText] = useState<string>("");

  const sendHandler = () => {
    if (text.length < 1) {
      present({ message: "Please enter your message...", buttons: ["ok"] });
      return false;
    }
    fetchPinnwand({ newpin: text }).then((res: any) => {
      onDismiss(true);
    });
  };
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Send New Pinnwand</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonTextarea
          placeholder="Please enter your message..."
          value={text}
          onIonChange={(e) => setText(e.detail.value!)}
        ></IonTextarea>
        <IonButton expand="block" onClick={() => sendHandler()}>
          Send
        </IonButton>
      </IonContent>
    </>
  );
};

export default PinnwandAdd;
