import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
} from "@ionic/react";
import { textFormat } from "../data/selectors";
import { getStatistics } from "../data/chatApi";

interface UserStatisticsProps {
  user_id: string;
  onDismiss: () => void;
}

const UserStatistics: React.FC<UserStatisticsProps> = ({
  user_id,
  onDismiss,
}) => {
  const [content, setContent] = useState("");
  useEffect(() => {
    getStatistics({ user_id }).then((res: any) => {
      setContent(res);
    });
  }, [user_id]);
  //url , code, width , height
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>User Statistics</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          className="ion-padding custom-table"
          dangerouslySetInnerHTML={{
            __html: textFormat(
              content.replaceAll('src="', 'src="https://www.cleefchat.de/')
            ),
          }}
        ></div>
      </IonContent>
    </>
  );
};

export default UserStatistics;
