import React from "react";
import { getGroupedMessages } from "../data/selectors";
import { connect } from "../data/connect";
import "./ChatBubble.css";
import Messages from "./Messages";

interface ChatBubbleProps {
  pv?: boolean;
}

interface StateProps {
  myid: string;
  messages: any;
  pv?: boolean;
  unread?: number;
  column?: string;
  paste: (str: string) => void;
}

interface DispatchProps {}

interface ChatBubbleProps extends StateProps, DispatchProps {}
const ChatBubble: React.FC<ChatBubbleProps> = ({
  myid,
  messages,
  pv = false,
  unread = 0,
  column = "room",
  paste,
}) => {
  const grouped = getGroupedMessages(messages, unread); //useMemo(() => , [messages]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: grouped.length ? "flex-end" : "center",
        flexDirection: "column",
        minHeight: "100%",
        boxSizing: "border-box",
      }}
    >
      {!!grouped.length ? (
        grouped.map((mess: any, key) => {
          return mess[0].type == "unread" ? (
            <div key="unread" id={`unseen_${column}`} className="unreadmessage">
              ungelesene Nachrichten
            </div>
          ) : (
            <div
              key={"g" + key}
              className={`messages ${
                mess[0].user_id === "1"
                  ? "system"
                  : mess[0].user_id === myid
                  ? "mine"
                  : "yours"
              }`}
            >
              <Messages mess={mess} pv={pv} paste={paste} />
            </div>
          );
        })
      ) : (
        <div className="messages system">
          <div className="message">noch keine Nachrichten.</div>
        </div>
      )}
      {}
    </div>
  );
};
export default connect({
  mapStateToProps: (state) => ({
    myid: state.user.user_id,
    kick_perms: state.user.perms_kick_user,
    privs: state.user.user_all_privs,
  }),
  mapDispatchToProps: {},
  component: ChatBubble,
});
