import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonSpinner,
  IonProgressBar,
  useIonAlert,
  useIonModal,
} from "@ionic/react";
import { fetchAlteQuiz, fetchQuiz } from "../data/chatApi";
import QuizAddQuestion from "../sections/quiz/QuizAddQuestion";
import QuizRating from "../sections/quiz/QuizRating";
import { decodeHtml } from "../data/selectors";

const QuizGame: React.FC = () => {
  const [present] = useIonAlert();
  const [loading, setLoadting] = useState(true);
  const [state_a, setStateA] = useState<any>({});
  const [question, setQuestion] = useState<any>({});
  const [section, setSection] = useState("stat");
  const [result, setResult] = useState("");
  const [timer, setTimer] = useState(0);

  const loadData = () => {
    fetchQuiz({ function: "allowed2play" }).then((res: any) => {
      console.log(res);
      setLoadting(false);
      setStateA(res);
    });
  };

  const getQ = () => {
    setLoadting(true);
    fetchQuiz({ function: "getQ" }).then((res: any) => {
      console.log(res);
      setLoadting(false);
      setQuestion(res);
      setSection("question");
      setTimer(20);
    });
  };

  const getA = (answer: string) => {
    setLoadting(true);
    fetchQuiz({ function: "getA", answer }).then((res: any) => {
      console.log(res);
      setLoadting(false);
      setResult(res.res);
      setStateA(res.state_a);
      setSection("answer");
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (timer < 1 && section === "question") {
      getA("0");
    }
    const sto = setTimeout(() => {
      if (timer > 0) setTimer((time) => time - 1);
    }, 1000);
    return () => {
      clearTimeout(sto);
    };
  }, [timer]);

  const startHandler = () => {
    getQ();
  };

  const semmeln = () => {
    fetchAlteQuiz({ function: "collectCoins" }).then((res: any) => {
      if (res == "1") {
        setStateA((stata: any) => ({ ...state_a, coins: 0 }));
        present({ message: "Ihre Coins gesammelt.", buttons: ["ok"] });
      } else present({ message: res, buttons: ["ok"] });
    });
  };

  const qaqDismiss = () => {
    qaqClose();
  };
  const [addQuestions, qaqClose] = useIonModal(QuizAddQuestion, {
    onDismiss: qaqDismiss,
  });

  const qrDismiss = () => {
    qrClose();
  };
  const [quizRating, qrClose] = useIonModal(QuizRating, {
    onDismiss: qrDismiss,
  });
  /*id	"2"
last_answer	"2022-03-29 18:04:55"
date_last_answer	"2022-03-29 18:04:55"
answers_counter_24h	"0"
answers_counter	"133"
answers_right_24h	"0"
answers_wrong_24h	"0"
answers_right	"77"
answers_wrong	"56"
last_question_id	"4430"
coins	"1"
today_stop	false*/
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Quiz-Spiele</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {loading && <IonSpinner />}
        {!loading && ["stat", "answer"].includes(section) && (
          <>
            {result === "" && !state_a.today_stop && (
              <>
                <p>
                  Pro Tag kannst du maximal 30 Fragen beantworten. Wenn du 90%
                  (=27) oder mehr dieser Fragen richtig beantwortest, erhälst du
                  den Titel Quizmaster! Dieser Titel steht anschließend in
                  deiner Chatstatistik (demnächst).
                </p>
                <p>
                  Außerdem bekommst du für jede richtig beantwortete Frage 1
                  Coin. Bei falscher Beantwortung werden dir Coins abgezogen.
                </p>
                <p>
                  Für Frageautoren: bei jeder akzeptierter Quiz-Frage erhälst du
                  4 Coins auf deinem Konto gutgeschrieben.
                </p>
              </>
            )}
            {result == "richtig" && (
              <IonText color="success">
                <h2>Richtig!</h2>
              </IonText>
            )}
            {result == "falsch" && (
              <IonText color="danger">
                <h2>Leider falsch!</h2>
              </IonText>
            )}
            {result == "timeout" && (
              <IonText color="warning">
                <h2>Die Zeit ist leider abgelaufen!</h2>
              </IonText>
            )}
            {section === "stat" && state_a.answers_counter_24h >= 30 && (
              <p>
                <b>
                  Du hast heute die Maximalanzahl von 30 Fragen beantwortet.
                  <br />
                  Morgen ab 24:00 Uhr gehts für dich mit dem Quiz weiter.
                </b>
              </p>
            )}
            <IonGrid>
              <IonRow>
                <IonCol>Deine Quiz-Statistik für heute</IonCol>
                <IonCol>Deine Quiz-Statistik gesamt</IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText color="success">
                    richtig: {state_a.answers_right_24h!}
                  </IonText>
                </IonCol>
                <IonCol>
                  <IonText color="success">
                    richtig: {state_a.answers_right!}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText color="danger">
                    falsch: {state_a.answers_wrong_24h!}
                  </IonText>
                </IonCol>
                <IonCol>
                  <IonText color="danger">
                    falsch: {state_a.answers_wrong!}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText>Coins: {state_a.coins!} </IonText>
                  <IonButton
                    size="small"
                    fill="outline"
                    onClick={() => {
                      semmeln();
                    }}
                  >
                    sammeln
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {(section !== "answer" ||
              !state_a.today_stop ||
              (state_a.answers_counter_24h > 0
                ? Math.round(
                    (100 / state_a.answers_counter_24h) *
                      state_a.answers_right_24h
                  )
                : 0) < 90) && (
              <p>
                Rating an richtigen Antworten heute:{" "}
                {state_a.answers_counter_24h > 0
                  ? Math.round(
                      (100 / state_a.answers_counter_24h) *
                        state_a.answers_right_24h
                    )
                  : 0}
                %
              </p>
            )}
            {section === "answer" &&
              state_a.today_stop &&
              (state_a.answers_counter_24h > 0
                ? Math.round(
                    (100 / state_a.answers_counter_24h) *
                      state_a.answers_right_24h
                  )
                : 0) >= 90 && (
                <>
                  <img src="https://www.cleefchat.de/img/status_qm.png" />{" "}
                  <b>
                    Gratulation, du hast{" "}
                    {state_a.answers_counter_24h > 0
                      ? Math.round(
                          (100 / state_a.answers_counter_24h) *
                            state_a.answers_right_24h
                        )
                      : 0}
                    % der Fragen heute richtig beantwortet und hast somit den
                    Titel "Quizmaster" verdient!!!
                  </b>
                  <br />
                  <br />
                  Morgen ab 24:00 Uhr gehts für dich mit dem Quiz weiter.
                </>
              )}
            {section === "stat" &&
              state_a.today_stop &&
              (state_a.answers_counter_24h > 0
                ? Math.round(
                    (100 / state_a.answers_counter_24h) *
                      state_a.answers_right_24h
                  )
                : 0) >= 90 && (
                <>
                  <br />
                  <span style={{ fontSize: "1.4em", color: "#dd0000" }}>
                    <b>
                      Gratulation, du bist heute ein{" "}
                      <img src="https://www.cleefchat.de/img/status_qm.png" />{" "}
                      QUIZMASTER!{" "}
                      <img src="https://www.cleefchat.de/img/status_qm.png" />
                    </b>
                  </span>
                  <br />
                  <p>
                    Deinen Online-Status schm&uuml;ckt in den kommenden 24
                    Stunden ein QUIZMASTER-Stern!
                  </p>
                  <br />
                </>
              )}
            {section === "answer" && !state_a.today_stop && (
              <p>
                Bei einem Endrating von mind. 90% nach 30 Fragen, erhälst du den
                Titel "Quizmaster"!!!
              </p>
            )}
            {!state_a.today_stop && (
              <IonButton
                size="large"
                expand="block"
                onClick={() => startHandler()}
              >
                {section == "stat" ? "Start" : "Weitere Frage"}
                <br />
                (Frage {parseInt(state_a.answers_counter_24h || 0) + 1} von 30)
              </IonButton>
            )}
            {section == "stat" && state_a.id == "2" && (
              <>
                <IonButton
                  color="light"
                  expand="block"
                  onClick={() => quizRating()}
                >
                  Quiz-Rating
                </IonButton>
                <IonButton
                  color="light"
                  expand="block"
                  onClick={() => addQuestions()}
                >
                  Neue Frage vorschlagen 4+ coins
                </IonButton>
                <IonButton color="light" expand="block">
                  Rating der Fragenautoren
                </IonButton>
                <IonButton color="light" expand="block">
                  Ihre gesendeten Fragen
                </IonButton>
              </>
            )}
          </>
        )}
        {!loading && section == "question" && (
          <>
            <IonProgressBar value={timer / 20}></IonProgressBar>
            <IonText>
              <h2>{decodeHtml(question.frage || "")}</h2>
            </IonText>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    size="large"
                    expand="block"
                    onClick={() => getA(question.answers[0][1] || "")}
                  >
                    {decodeHtml(question.answers[0][0] || "")}
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    size="large"
                    expand="block"
                    onClick={() => getA(question.answers[1][1] || "")}
                  >
                    {decodeHtml(question.answers[1][0] || "")}
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton
                    size="large"
                    expand="block"
                    onClick={() => getA(question.answers[2][1] || "")}
                  >
                    {decodeHtml(question.answers[2][0] || "")}
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    size="large"
                    expand="block"
                    onClick={() => getA(question.answers[3][1] || "")}
                  >
                    {decodeHtml(question.answers[3][0] || "")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            {question.from_user != "" && (
              <IonText color="meduim">
                Frage von {decodeHtml(question.from_user || "")}
              </IonText>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default QuizGame;
