import {
  IonPage,
  IonContent,
  IonInput,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  useIonAlert,
  IonCheckbox,
  IonListHeader,
} from "@ionic/react";
import React, { useState } from "react";
import { femaleOutline, maleOutline, maleFemaleOutline } from "ionicons/icons";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { connect } from "../data/connect";
import { loadUserData } from "../data/user/user.actions";
import { login, setUserData } from "../data/chatApi";

interface StateProps {
  isLogined: boolean;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
}

interface LoginProps extends RouteComponentProps, StateProps, DispatchProps {}
const Login: React.FC<LoginProps> = ({ history, isLogined, loadUserData }) => {
  const [loginData, setLoginData] = useState({
    username: "",
    gender: "f",
    password: "",
    captcha: "",
    invisible: false,
  });
  const [showPasswordLayer, setShowPasswordLayer] = useState(false);
  const [showInvisibleLayer, setShowInvisibleLayer] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [present] = useIonAlert();
  let colorGender = "dark";
  if (loginData.gender === "m") colorGender = "primary";
  if (loginData.gender === "f") colorGender = "danger";

  const setUsernameHandler = (event: any) => {
    setLoginData({ ...loginData, username: event.detail.value });
  };
  const setGenderHandler = (event: any) => {
    setLoginData({ ...loginData, gender: event.detail.value });
  };
  const setPasswordHandle = (event: any) => {
    setLoginData({ ...loginData, password: event.detail.value });
  };
  const setCaptchaHandle = (event: any) => {
    setLoginData({ ...loginData, captcha: event.detail.value });
  };
  const setInvisibleHandle = (event: any) => {
    setLoginData({ ...loginData, invisible: !!event.detail.checked });
  };

  const loginClick = () => {
    let params = {
      username: loginData.username,
      gender: loginData.gender,
      pw: loginData.password,
      status_invisible: loginData.invisible,
      captcha: loginData.captcha,
    };
    login(params)
      .then((result: any) => {
        if (result === "1") {
          setUserData(loginData);
          loadUserData();
        } else {
          if (result === "pw" || result === "pw+invisible") {
            setShowPasswordLayer(true);
            if (result === "pw+invisible") setShowInvisibleLayer(true);
            setShowCaptcha(false);
          } else if (result === "captcha") {
            setShowCaptcha(true);
          } else {
            if (result === "blacklist") {
              history.push("/blacklist/block");
            } else if (result === "nocookie") {
              console.log(result);
            } else if (result !== "") {
              present({
                //cssClass: 'my-css',
                header: "Alert",
                message: result,
                buttons: [
                  "Ok",
                  //{ text: "Ok", handler: (d) => console.log("ok pressed") },
                ],
                //onDidDismiss: (e) => console.log("did dismiss"),
              });
            } else {
              setLoginData({ ...loginData, username: "" });
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      loginClick();
    }
  };
  if (isLogined) {
    return <Redirect exact to="/chat/room" />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Cleef Chat</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonListHeader>
            <IonLabel>Anmelden</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel position="stacked">Dein Nickname:</IonLabel>
            <IonInput
              value={loginData.username}
              placeholder="Dein nick..."
              onIonChange={setUsernameHandler}
              onKeyUp={handleKeyPress}
            />
          </IonItem>
          <div style={{ marginTop: "1rem", padding: "0 15px" }}>
            <IonSegment
              onIonChange={setGenderHandler}
              color={colorGender}
              value={loginData.gender}
            >
              <IonSegmentButton value="m">
                <IonLabel>
                  <IonIcon icon={maleOutline} slot="start" />
                  <span style={{ marginLeft: "5px" }}>Male</span>
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="f">
                <IonLabel>
                  <IonIcon icon={femaleOutline} slot="start" />
                  <span style={{ marginLeft: "5px" }}>Female</span>
                </IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="n">
                <IonLabel>
                  <IonIcon icon={maleFemaleOutline} slot="start" />
                  <span style={{ marginLeft: "5px" }}>Others</span>
                </IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          {showPasswordLayer && (
            <IonItem>
              <IonLabel position="stacked">Password:</IonLabel>
              <IonInput
                value={loginData.password}
                type="password"
                placeholder="Password..."
                onIonChange={setPasswordHandle}
                onKeyUp={handleKeyPress}
              />
            </IonItem>
          )}
          {showCaptcha && (
            <IonItem>
              <img
                src="https://www.cleefchat.de/?Captcha"
                alt="captcha"
                width="114px"
                height="32px"
              />
              <IonLabel position="stacked">Captcha:</IonLabel>
              <IonInput
                value={loginData.captcha}
                placeholder="Captcha hier eingeben..."
                onIonChange={setCaptchaHandle}
              />
            </IonItem>
          )}
          {showInvisibleLayer && (
            <IonItem>
              <IonCheckbox
                checked={loginData.invisible}
                onIonChange={setInvisibleHandle}
              />
              <IonLabel>Unsichtbar einlogen.</IonLabel>
            </IonItem>
          )}
        </IonList>
        <div style={{ marginTop: "1rem", padding: "0 15px" }}>
          <IonButton expand="block" onClick={loginClick}>
            Los gehts!
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    isLogined: state.user.isLoggedin,
  }),
  mapDispatchToProps: { loadUserData },
  component: withRouter(Login),
});
