import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import { connect } from "../data/connect";
import { fetchStatus } from "../data/chatApi";
import { changeStatus } from "../data/user/user.actions";
import { reloaderUserOnline } from "../data/messages/messages.actions";

interface StateProps {
  myId: string;
  room: string;
  status: string;
  statuses: any;
  onDismiss: () => void;
}

interface DispatchProps {
  changeStatus: typeof changeStatus;
  reloaderUserOnline: typeof reloaderUserOnline;
}
interface ChangeStatusProps extends StateProps, DispatchProps {}
const ChangeStatus: React.FC<ChangeStatusProps> = ({
  status,
  statuses,
  myId,
  room,
  changeStatus,
  reloaderUserOnline,
  onDismiss,
}) => {
  const setSelected = (stat: string) => {
    changeStatus(stat);
    let find = statuses.filter((obj: any) => {
      return obj.imgname === stat;
    });
    fetchStatus({ img: stat, text: find[0].text }).then((res: any) => {
      // eslint-disable-next-line
      if (res == "1") reloaderUserOnline(myId, room);
    });
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Change Status</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonRadioGroup
            value={status}
            onIonChange={(e) => setSelected(e.detail.value)}
          >
            {statuses.map((item: any) => (
              <IonItem key={item.imgname}>
                <IonLabel>
                  <img
                    src={`https://www.cleefchat.de/img/${item.imgname}.png`}
                    alt={item.text}
                    width={16}
                    height={16}
                  />{" "}
                  {item.text}
                </IonLabel>
                <IonRadio slot="start" value={item.imgname} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </IonContent>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    myId: state.user.user_id,
    room: state.messages.room,
    status: state.user.current_status || "status_online",
    statuses: state.user.status,
  }),
  mapDispatchToProps: { changeStatus, reloaderUserOnline },
  component: ChangeStatus,
});
