import React, { useState } from "react";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonTextarea,
  useIonAlert,
} from "@ionic/react";
import { banOutline, refreshOutline } from "ionicons/icons";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { fetchComplaint } from "../data/chatApi";
function getCookie(cname: any) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return ".";
}
const Kicked: React.FC<RouteComponentProps> = ({ history }) => {
  const [text, setText] = useState("");
  const [show, setShow] = useState(true);
  const { reason } = useParams<{ reason: string }>();
  const [present] = useIonAlert();

  const getBlockTime = () => {
    const time = getCookie("cookie_etchat_blacklist_until");
    console.log(time);
    if (typeof time === "number") {
      const date = new Date(time * 1000);
      return (
        " bis zum: " +
        date.getUTCDay +
        "." +
        date.getUTCMonth() +
        "." +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    }
    return "";
  };

  const sendHandler = () => {
    fetchComplaint(text).then((res: any) => {
      present({
        message: res == "1" ? "Ihre beschwerde nachricht wurde gesendet" : res,
        buttons: ["Ok"],
      });
      if (res == "1") setShow(false);
    });
  };

  return (
    <IonPage>
      <IonContent className="ion-padding ion-text-center">
        <IonIcon icon={banOutline} size="large"></IonIcon>
        <h1>
          {reason == "flood" &&
            "Sie wurden automatisch aus dem Chat für 5 Minuten ausgeschlossen wegen Flud."}
          {reason == "kick" &&
            "Sie wurden aus dem Chat gekickt, dürfen aber sofort wieder rein!"}
          {reason == "block" &&
            "Sie wurden aus dem Chat ausgeschlossen" + getBlockTime()}
        </h1>
        <p>Bei Fragen wenden Sie sich an den Chatbetreiber.</p>
        <IonButton
          color="secondary"
          href="https://www.cleefchat.de/regeln"
          fill="outline"
        >
          Zum Chat-Regeln
        </IonButton>
        <IonButton
          onClick={() => {
            history.push("/login");
          }}
          fill="outline"
        >
          <IonIcon icon={refreshOutline}></IonIcon> Zurück zur Anmeldeseite
        </IonButton>
        {reason == "block" && show && (
          <>
            <h2>Sie haben Beschwerde?</h2>
            <IonTextarea
              placeholder="Schreiben Sie Ihre Beschwerde hier..."
              onIonChange={(e) => setText(e.detail.value!)}
            ></IonTextarea>
            <IonButton
              color="warning"
              fill="outline"
              onClick={() => sendHandler()}
            >
              senden
            </IonButton>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Kicked);
