import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonText,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  useIonAlert,
} from "@ionic/react";
import { fetchAlteQuiz } from "../../data/chatApi";

interface QAQProps {
  onDismiss: () => void;
}

const QuizAddQuestion: React.FC<QAQProps> = ({ onDismiss }) => {
  const [present] = useIonAlert();
  const [loading, setLoading] = useState(false);
  const [frage, setFrage] = useState("");
  const [richtige, setRechtige] = useState("");
  const [falsch1, setFalsch1] = useState("");
  const [falsch2, setFalsch2] = useState("");
  const [falsch3, setFalsch3] = useState("");

  const submit = () => {
    if (
      frage == "" ||
      richtige == "" ||
      falsch1 == "" ||
      falsch2 == "" ||
      falsch3 == ""
    ) {
      present({
        message: "Bitte alle notwendigen Felder ausfüllen.",
        buttons: ["ok"],
      });
      return false;
    }
    setLoading(true);
    fetchAlteQuiz({
      function: "insertNewQ",
      f_new_frage: frage,
      f_new_a1: richtige,
      f_new_a2: falsch1,
      f_new_a3: falsch2,
      f_new_a4: falsch3,
    }).then((res: any) => {
      setLoading(false);
      setFrage("");
      setRechtige("");
      setFalsch1("");
      setFalsch2("");
      setFalsch3("");
      present({
        header: "Vielen Dank!",
        message: "Deine Frage wird gepr&uuml;ft und danach freigeschaltet",
        buttons: ["ok"],
      });
    });
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Neue Frage vorschlagen</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText>
          Möchtest du eine Frage erstellen und uns zusenden? Wir würden uns
          darüber freuen!
        </IonText>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Frage</IonLabel>
            <IonInput
              value={frage}
              onIonChange={(e: any) => setFrage(e.target.value || "")}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Richtige Antwort</IonLabel>
            <IonInput
              value={richtige}
              onIonChange={(e: any) => setRechtige(e.target.value || "")}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Falsche Antwort 1</IonLabel>
            <IonInput
              value={falsch1}
              onIonChange={(e: any) => setFalsch1(e.target.value || "")}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Falsche Antwort 2</IonLabel>
            <IonInput
              value={falsch2}
              onIonChange={(e: any) => setFalsch2(e.target.value || "")}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>Falsche Antwort 3</IonLabel>
            <IonInput
              value={falsch3}
              onIonChange={(e: any) => setFalsch3(e.target.value || "")}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton
          disabled={loading}
          onClick={() => {
            submit();
          }}
        >
          Eintragen
        </IonButton>
        <IonButton
          color="medium"
          onClick={() => {
            onDismiss();
          }}
        >
          Abbrechen
        </IonButton>
      </IonContent>
    </>
  );
};

export default QuizAddQuestion;
