import React, { useEffect } from "react";
import { IonSplitPane, IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import Room from "./Room";
import Privat from "./Privat";
import Menu from "../components/Menu";
import Profile from "./Profile";

import { connect } from "../data/connect";
import {
  setSmileys,
  reloaderUserOnline,
  reloaderMessages,
  getRecentChats,
} from "../data/messages/messages.actions";
import {
  changeStatus,
  setSplit,
  logout,
  userCallbackFunctions as UCF,
  setDailyPrize,
} from "../data/user/user.actions";
import Pinnwand from "./Pinnwand";
import Setting from "./Setting";
import Friends from "./Friends";
import TopUsers from "./TopUsers";
import "../theme/custom.css";
import { fetchDailyPrize, fetchStatus } from "../data/chatApi";
import KickBox from "./KickBox";
import QuizGame from "./QuizGame";

interface StateProps {
  darkMode?: boolean;
  split: boolean;
  room: string;
  myId: string;
  priv: string;
  last_id: string;
  status: string;
  isLogined: boolean;
}

interface DispatchProps {
  UCF: typeof UCF;
  setDailyPrize: typeof setDailyPrize;
  reloaderUserOnline: typeof reloaderUserOnline;
  reloaderMessages: typeof reloaderMessages;
  getRecentChats: typeof getRecentChats;
  setSplit: typeof setSplit;
  setSmileys: typeof setSmileys;
  logout: typeof logout;
}

interface MainProps extends RouteComponentProps, StateProps, DispatchProps {}

const Main: React.FC<MainProps> = ({
  room,
  myId,
  priv,
  last_id,
  status,
  isLogined,
  split,
  UCF,
  setDailyPrize,
  reloaderUserOnline,
  reloaderMessages,
  getRecentChats,
  setSplit,
  setSmileys,
  history,
}) => {
  const callback = (res: string) => {
    UCF(res, history);
  };

  useEffect(() => {
    if (status === "status_online") {
      changeStatus("status_telephone");
      fetchStatus({ img: "status_telephone", text: "Ist via handy online" });
    }
    if (priv !== "gast") {
      fetchDailyPrize().then((res: any) => {
        if (res.slice(0, 10) == "newchance:")
          setDailyPrize(true, res.slice(10));
      });
    }
    fetch("https://www.cleefchat.de/?Smileys&json=1", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((result) => {
        setSmileys(result);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [myId]);

  useEffect(() => {
    setTimeout(function () {
      getRecentChats();
      reloaderUserOnline(myId, room);
    }, 500);
    const olint = setInterval(function () {
      reloaderUserOnline(myId, room);
    }, 10000);
    return () => clearInterval(olint);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const mont = setTimeout(function () {
      if (room == "1") reloaderMessages(room, "", myId, callback);
    }, 200);
    return () => {
      clearTimeout(mont);
    };
    // eslint-disable-next-line
  }, [room, myId]);

  useEffect(() => {
    const mint = setInterval(function () {
      reloaderMessages(room, last_id, myId, callback);
    }, 5000);
    return () => {
      clearInterval(mint);
    };
    // eslint-disable-next-line
  }, [room, myId, last_id]);

  if (!isLogined) {
    return <Redirect exact to="/login" />;
  }

  return (
    <IonSplitPane
      contentId="main"
      onIonSplitPaneVisible={(e: any) => {
        if (e.detail.visible && !split) setSplit(true);
        else if (split) setSplit(false);
      }}
    >
      <Menu />
      <IonRouterOutlet id="main">
        <Route path="/chat/:id([0-9]+)" component={Privat} />
        <Route path="/chat/profile/:id([0-9]+)" component={Profile} />
        <Route path="/chat/kick/:user_id([0-9]+)" component={KickBox} />
        <Route exact path="/chat/room" component={Room} />
        <Route exact path="/chat/setting" component={Setting} />
        <Route exact path="/chat/pinnwand" component={Pinnwand} />
        <Route exact path="/chat/friends" component={Friends} />
        <Route exact path="/chat/topusers" component={TopUsers} />
        <Route exact path="/chat/quiz" component={QuizGame} />
        <Redirect exact from="/chat" to="/chat/room" />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    room: state.messages.room,
    myId: state.user.user_id,
    priv: state.user.user_priv,
    last_id: state.messages.last_id,
    isLogined: state.user.isLoggedin,
    status: state.user.current_status || "status_online",
    split: state.user.split,
  }),
  mapDispatchToProps: {
    UCF,
    setSplit,
    setSmileys,
    getRecentChats,
    reloaderUserOnline,
    reloaderMessages,
    logout,
    setDailyPrize,
  },
  component: withRouter(Main),
});
