import React from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
} from "@ionic/react";
import { Smiley } from "../models/Smiley";

interface SmileysProps {
  smileys: Smiley[];
  choose: (i: string) => void;
  onDismiss: () => void;
}

const Smileys: React.FC<SmileysProps> = ({ smileys, choose, onDismiss }) => {
  //url , code, width , height
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Smileys</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {smileys.map((smiley) => {
          return (
            <img
              src={smiley.url}
              key={smiley.code}
              style={{ margin: "5px" }}
              alt={smiley.code}
              width={smiley.width}
              height={smiley.height}
              onClick={() => {
                choose(smiley.code);
              }}
            />
          );
        })}
      </IonContent>
    </>
  );
};

export default Smileys;
