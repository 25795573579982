import { connect } from "../data/connect";
import { Recent } from "../models/Recent";
import {
  IonAvatar,
  IonBadge,
  IonIcon,
  IonItem,
  IonLabel,
  IonNote,
} from "@ionic/react";
import { FC } from "react";
import { decodeHtml, unixToTime } from "../data/selectors";
import { female, male, maleFemale } from "ionicons/icons";

interface StateProps {
  myId: string;
  user: Recent;
  unread: string;
}

interface DispatchProps {}

interface ChatItemProps extends StateProps, DispatchProps {}

const ChatItem: FC<ChatItemProps> = ({ myId, user, unread }) => {
  //const user: Recent = getUserById(user_id);
  const name = (user.user || "").replace("<i>", "").replace("</i>", "");
  const count = (unread[parseInt(user.user_id)] || []).length;
  return (
    <IonItem
      detail={false}
      routerLink={"/chat/" + user.user_id}
      routerDirection="none"
      /*button
        onClick={() => history.push(`/chat/${ol.user_id}`)}*/
    >
      <IonAvatar slot="start">
        <img
          src={"https://www.cleefchat.de" + user.user_avatar}
          alt={user.user}
        />
      </IonAvatar>
      <IonLabel>
        <h2>
          {genIcon(user.user_sex)} {name}
        </h2>
        <p>
          {
            // eslint-disable-next-line
            user.sender == myId && (
              <span style={{ color: "#3880ff" }}>Du: </span>
            )
          }
          {decodeHtml(user.message.replace(/(<([^>]+)>)/gi, " Bilder "))}
        </p>
      </IonLabel>
      <div
        style={{
          display: "flex",
          gap: 5,
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <IonNote>{unixToTime(user.time)}</IonNote>
        {!!count && (
          <div>
            <IonBadge color="danger">{count}</IonBadge>
          </div>
        )}
      </div>
    </IonItem>
  );
};
const genIcon = (gen: string) => {
  let icon = maleFemale;
  let color = "dark";
  if (gen === "f") icon = female;
  if (gen === "m") icon = male;
  if (gen === "f") color = "danger";
  if (gen === "m") color = "primary";
  return <IonIcon icon={icon} color={color} slot="start" />;
};
export default connect({
  mapStateToProps: (state) => ({
    myId: state.user.user_id,
    unread: state.messages.unread,
  }),
  mapDispatchToProps: {},
  component: ChatItem,
});
