import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonBadge,
} from "@ionic/react";
import { connect } from "../data/connect";
import { giftOutline } from "ionicons/icons";
import { fetchDailyPrize } from "../data/chatApi";
import { setDailyPrize } from "../data/user/user.actions";

interface DailyPrizeProps {
  days: string;
  onDismiss: () => void;
  setDailyPrize: typeof setDailyPrize;
}

const DailyPrize: React.FC<DailyPrizeProps> = ({
  days,
  setDailyPrize,
  onDismiss,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [win, setWin] = useState("");
  const [wincolor, setWincolor] = useState("");
  const selectHandler = (num: number) => {
    setDisabled(true);
    if (num === 1) setWincolor("primary");
    if (num === 2) setWincolor("success");
    if (num === 3) setWincolor("danger");
    fetchDailyPrize({ selectbox: num }).then((res: any) => {
      setWin(res);
      setDailyPrize(false, days);
    });
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>tägliches Geschenk</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonLabel>Wähle dein Geschenk aus!</IonLabel>
          <IonBadge slot="end" color="danger">
            {days} {parseInt(days) > 1 ? "Tage" : "Tag"}
          </IonBadge>
        </IonItem>
        {win.length > 0 ? (
          <IonGrid>
            <IonRow>
              <IonCol size="3">
                <IonButton color={wincolor} size="large">
                  <IonIcon icon={giftOutline} slot="icon-only" />
                </IonButton>
              </IonCol>
              <IonCol size="9">
                <p>
                  <h3>
                    sie <b>{win}</b> gewonnen
                  </h3>
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  color="primary"
                  expand="block"
                  size="large"
                  onClick={() => selectHandler(1)}
                  disabled={disabled}
                >
                  <IonIcon icon={giftOutline} slot="icon-only" />
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  color="success"
                  expand="block"
                  size="large"
                  onClick={() => selectHandler(2)}
                  disabled={disabled}
                >
                  <IonIcon icon={giftOutline} slot="icon-only" />
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  color="danger"
                  expand="block"
                  size="large"
                  onClick={() => selectHandler(3)}
                  disabled={disabled}
                >
                  <IonIcon icon={giftOutline} slot="icon-only" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        )}

        <ul>
          <li>
            Jeden Tag kannst du dein Gl&uuml;ck versuchen. &ouml;ffne{" "}
            <i>eine Box</i> und gewinne mit etwas Gl&uuml;ck satte Gewinne!!
          </li>
          <br />
          <li>
            <b>Hinweis:</b> Die Geschenke sind unterschiedlich wertig.
            <br />
            Viel Erfolg!.
          </li>
        </ul>
      </IonContent>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    days: state.user.dailyPrizeDays,
  }),
  mapDispatchToProps: { setDailyPrize },
  component: DailyPrize,
});
