import React from "react";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuToggle,
  useIonAlert,
} from "@ionic/react";
import { chatboxOutline, keyOutline, lockClosedOutline } from "ionicons/icons";
import { changeRoomMessage } from "../data/messages/messages.actions";
import { userCallbackFunctions as UCF } from "../data/user/user.actions";
import { connect } from "../data/connect";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CheckRoomPw } from "../data/chatApi";

interface StateProps {
  myId: string;
  cRoom: string;
  last_id: string;
  room: string;
  room_id: string;
  room_allowed: string;
}

interface DispatchProps {
  changeRoomMessage: typeof changeRoomMessage;
  UCF: typeof UCF;
}

interface RoomProps extends RouteComponentProps, StateProps, DispatchProps {}

const Room: React.FC<RoomProps> = ({
  room,
  room_id,
  room_allowed,
  last_id,
  myId,
  cRoom,
  history,
  changeRoomMessage,
}) => {
  const [present] = useIonAlert();

  const callback = (res: string) => {
    UCF(res, history);
  };

  const changeRoomHandler = () => {
    if (room_id === cRoom) return;
    if (room_allowed == "0" || room_allowed == "3")
      present({
        message: "Sie haben keine Rechte um diesen Raum zu betretten.",
        buttons: ["ok"],
      });
    else if (room_allowed == "2")
      present({
        message:
          "Dieser Raum ist geschützt durch einen Passwort. Bitte Passwort eingeben:",
        inputs: [
          {
            name: "pass",
            type: "text",
            placeholder: "passwort...",
          },
        ],
        buttons: [
          { text: "Cancel", role: "cancel" },
          {
            text: "Join",
            handler: (d: any) => {
              CheckRoomPw({ layerpw: d.pass, roomid: room_id }).then(
                (res: any) => {
                  if (res == "1")
                    changeRoomMessage(room_id, room, last_id, myId, callback);
                  else
                    present({
                      message: "Passwort ist Falsch!",
                      buttons: ["ok"],
                    });
                }
              );
            },
          },
        ],
        //onDidDismiss: () => {},
      });
    else changeRoomMessage(room_id, room, last_id, myId, callback);
    console.log(room_id, room, last_id, myId);
  };
  return (
    <IonMenuToggle auto-hide="false">
      <IonItem
        button
        color={roomColor(room_allowed)}
        detail={false}
        disabled={room_id == cRoom}
        onClick={() => {
          changeRoomHandler();
        }}
        routerDirection="none"
      >
        <IonLabel>
          <h2>
            {roomIcon(room_allowed)} {room}
          </h2>
        </IonLabel>
      </IonItem>
    </IonMenuToggle>
  );
};
const roomIcon = (room_allowed: string) => {
  if (room_allowed == "0")
    return <IonIcon icon={lockClosedOutline} slot="start" />;
  if (room_allowed == "2") return <IonIcon icon={keyOutline} slot="start" />;
  return <IonIcon icon={chatboxOutline} slot="start" />;
};
const roomColor = (room_allowed: string) => {
  if (room_allowed != "1") return "danger";
  return "light";
};

export default connect({
  mapStateToProps: (state) => ({
    cRoom: state.messages.room,
    myId: state.user.user_id,
    last_id: state.messages.last_id,
  }),
  mapDispatchToProps: {
    changeRoomMessage,
    UCF,
  },
  component: withRouter(Room),
});
