import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonFooter,
  IonCol,
  IonRow,
  IonGrid,
} from "@ionic/react";
import { textFormat } from "../data/selectors";
import { getAbuse } from "../data/chatApi";

interface MeldenProps {
  user_id: string;
  onDismiss: () => void;
}

const Melden: React.FC<MeldenProps> = ({ user_id, onDismiss }) => {
  const [content, setContent] = useState("");
  const [showReport, setShowReport] = useState(false);
  const handleMelden = () => {
    getAbuse({ make_abuse: "1" });
    onDismiss();
  };
  useEffect(() => {
    getAbuse({ userid: user_id }).then((res: any) => {
      if (res.match(/id="verstoss_melden"/)) setShowReport(true);
      setContent(res);
    });
  }, []);
  //url , code, width , height
  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Verstoß melden</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          className="ion-padding"
          dangerouslySetInnerHTML={{
            __html: textFormat(content),
          }}
        ></div>
      </IonContent>
      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color="dark"
                onClick={() => {
                  onDismiss();
                }}
              >
                schließen
              </IonButton>
            </IonCol>
            {showReport && (
              <IonCol>
                <IonButton onClick={() => handleMelden()} expand="block">
                  Verstoß melden
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  );
};

export default Melden;
