import {
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonAvatar,
  IonSelect,
  IonSelectOption,
  IonToggle,
  useIonModal,
  IonNote,
  IonListHeader,
} from "@ionic/react";
import React from "react";
import {
  banOutline,
  cartOutline,
  chatbubblesOutline,
  compassOutline,
  constructOutline,
  exitOutline,
  eyeOffOutline,
  giftOutline,
  helpOutline,
  imageOutline,
  keyOutline,
  lockClosedOutline,
  moonOutline,
  pencilOutline,
  peopleOutline,
  pinOutline,
  ribbonOutline,
  trophyOutline,
} from "ionicons/icons";

import { connect } from "../data/connect";
import {
  changePvLock,
  changeStatus,
  logout,
  setDarkMode,
  systemMessage,
} from "../data/user/user.actions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchBlockUser, fetchStatus } from "../data/chatApi";
import ChangePw from "../sections/ChangePw";
import RegisterPw from "../sections/RegisterPw";
import ChangeStatus from "../sections/ChangeStatus";
import UserStatistics from "../sections/UserStatistics";
import ChangeAvatar from "../sections/ChangeAvatar";
import BlockedUsers from "../sections/BlockedUsers";
import DailyPrize from "../sections/DailyPrize";

interface StateProps {
  myId: string;
  avatar: string;
  name: string;
  coins: string;
  priv: string;
  status: string;
  statuses: any;
  sysmess: boolean;
  pinnwandPermission: boolean;
  adminPermission: boolean;
  lockprivat: string;
  darkMode: boolean;
  daily_prize: boolean;
}

interface DispatchProps {
  logout: typeof logout;
  changeStatus: typeof changeStatus;
  changePvLock: typeof changePvLock;
  systemMessage: typeof systemMessage;
  setDarkMode: typeof setDarkMode;
}

interface SettingProps extends RouteComponentProps, StateProps, DispatchProps {}
const Setting: React.FC<SettingProps> = ({
  myId,
  name,
  avatar,
  priv,
  coins,
  status,
  statuses,
  sysmess,
  lockprivat,
  darkMode,
  daily_prize,
  pinnwandPermission,
  adminPermission,
  setDarkMode,
  changePvLock,
  systemMessage,
  logout,
  history,
}) => {
  const pvLockHandler = (pvlock: string) => {
    changePvLock(pvlock);
    fetchBlockUser({ privatmode: pvlock });
  };
  const sysMessHandler = (checked: boolean) => {
    systemMessage(checked);
    console.log();
    fetchStatus({ sys_messages: checked ? "1" : "0" });
  };

  const getStatusName = (status: string) => {
    let find = statuses.filter((obj: any) => {
      return obj.imgname === status;
    });
    return find[0] ? find[0].text : "unknown";
  };

  const statusDismiss = () => {
    closeStatus();
  };
  const [statusWin, closeStatus] = useIonModal(ChangeStatus, {
    onDismiss: statusDismiss,
  });
  const registerDismiss = () => {
    closeRegister();
  };
  const [registerWin, closeRegister] = useIonModal(RegisterPw, {
    onDismiss: registerDismiss,
  });
  const pwDismiss = () => {
    closePw();
  };
  const [pwWin, closePw] = useIonModal(ChangePw, {
    onDismiss: pwDismiss,
  });
  const pbDismiss = () => {
    closePb();
  };
  const [pbWin, closePb] = useIonModal(ChangeAvatar, {
    onDismiss: pbDismiss,
  });
  const statisticsDismiss = () => {
    closeStatistics();
  };
  const [showStatistics, closeStatistics] = useIonModal(UserStatistics, {
    user_id: myId,
    onDismiss: statisticsDismiss,
  });
  const blockedDismiss = () => {
    blockedDis();
  };
  const [blockedUsers, blockedDis] = useIonModal(BlockedUsers, {
    user_id: myId,
    onDismiss: blockedDismiss,
  });
  const dailyDismiss = () => {
    dailyClose();
  };
  const [dailyPrize, dailyClose] = useIonModal(DailyPrize, {
    onDismiss: dailyDismiss,
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/chat/room" />
          </IonButtons>
          <IonTitle>Einstellungen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem
            button
            onClick={() => {
              showStatistics();
            }}
          >
            <IonAvatar slot="start">
              <img src={`https://www.cleefchat.de/${avatar}`} alt={name} />
            </IonAvatar>
            <IonLabel>
              <h2>{name}</h2>
              <p>{coins} coins</p>
            </IonLabel>
          </IonItem>
          <IonListHeader>
            <IonLabel>Features</IonLabel>
          </IonListHeader>
          <IonItem
            detail
            href="https://www.cleefchat.de/forum/"
            target="_blank"
          >
            <IonLabel>
              <IonIcon icon={chatbubblesOutline} color="secondary" /> Forum
            </IonLabel>
          </IonItem>
          <IonItem
            detail={false}
            button
            onClick={() => history.push("/chat/topusers")}
          >
            <IonLabel>
              <IonIcon icon={trophyOutline} color="secondary" /> Top-Benutzer
            </IonLabel>
          </IonItem>
          {priv !== "gast" && (
            <>
              <IonItem
                detail={false}
                button
                onClick={() => history.push("/chat/friends")}
              >
                <IonLabel>
                  <IonIcon icon={peopleOutline} color="secondary" />{" "}
                  Freundesliste
                </IonLabel>
              </IonItem>
              <IonItem
                detail
                href="https://www.cleefchat.de/?ShopingIndex"
                target="_blank"
              >
                <IonLabel>
                  <IonIcon icon={cartOutline} color="secondary" /> Shopping
                  Center
                </IonLabel>
              </IonItem>
            </>
          )}
          {pinnwandPermission && (
            <IonItem
              detail={false}
              button
              onClick={() => {
                history.push("/chat/pinnwand");
              }}
            >
              <IonIcon icon={pinOutline} color="secondary" /> Pinnwand
            </IonItem>
          )}
          {priv !== "gast" && (
            <IonItem
              detail={false}
              button
              onClick={() => {
                history.push("/chat/quiz");
              }}
            >
              <IonIcon icon={helpOutline} color="secondary" /> Quiz
            </IonItem>
          )}
          <IonListHeader>
            <IonLabel>Account</IonLabel>
          </IonListHeader>
          {daily_prize && (
            <IonItem detail={false} button onClick={() => dailyPrize()}>
              <IonLabel>
                <IonIcon icon={giftOutline} color="success" /> tägliches
                Geschenk
              </IonLabel>
            </IonItem>
          )}
          <IonItem detail={false} button onClick={() => statusWin()}>
            <IonLabel>
              <IonIcon icon={ribbonOutline} color="success" /> Status ändern
            </IonLabel>
            <IonNote slot="end">
              <img
                src={`https://www.cleefchat.de/img/${status}.png`}
                alt={status}
                width={16}
                height={16}
              />{" "}
              {getStatusName(status)}
            </IonNote>
          </IonItem>
          {priv === "gast" && (
            <IonItem detail={false} button onClick={() => registerWin()}>
              <IonLabel>
                <IonIcon icon={keyOutline} color="success" /> Passwort
                registrieren
              </IonLabel>
            </IonItem>
          )}
          {priv !== "gast" && (
            <>
              <IonItem detail={false} button onClick={() => pwWin()}>
                <IonLabel>
                  <IonIcon icon={keyOutline} color="success" /> Passwort ändern
                </IonLabel>
              </IonItem>
              <IonItem detail={false} button onClick={() => pbWin()}>
                <IonLabel>
                  <IonIcon icon={imageOutline} color="success" /> Profilbild
                  ändern
                </IonLabel>
              </IonItem>
              <IonItem
                detail
                href="https://www.cleefchat.de/profile/edit.php"
                target="_blank"
              >
                <IonLabel>
                  <IonIcon icon={pencilOutline} color="success" /> Profil
                  bearbeiten
                </IonLabel>
              </IonItem>
            </>
          )}
          {adminPermission && (
            <IonItem
              detail
              href="https://www.cleefchat.de/admin/"
              target="_blank"
            >
              <IonLabel>
                <IonIcon icon={constructOutline} color="success" /> Admin menu
              </IonLabel>
            </IonItem>
          )}
          <IonListHeader>
            <IonLabel>Verwalten</IonLabel>
          </IonListHeader>
          <IonItem detail={false} button onClick={() => blockedUsers()}>
            <IonLabel>
              <IonIcon icon={banOutline} color="warning" /> Blocked Users
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonIcon icon={lockClosedOutline} color="warning" /> Privat
              Nachrichten
            </IonLabel>
            <IonSelect
              value={lockprivat}
              okText="Okay"
              cancelText="Dismiss"
              onIonChange={(e) => pvLockHandler(e.detail.value)}
            >
              <IonSelectOption value="all">alle an</IonSelectOption>
              <IonSelectOption value="friends">nur Freunde an</IonSelectOption>
              <IonSelectOption value="off">aus</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonIcon icon={eyeOffOutline} color="warning" /> System Nachricht
            </IonLabel>
            <IonToggle
              checked={sysmess}
              onIonChange={(e) => sysMessHandler(e.detail.checked)}
            />
          </IonItem>
          <IonItem>
            <IonLabel>
              <IonIcon icon={moonOutline} color="warning" /> Dunkler Modus
            </IonLabel>
            <IonToggle
              checked={darkMode}
              onIonChange={(e) => setDarkMode(e.detail.checked)}
            />
          </IonItem>
          <IonItem href="https://www.cleefchat.de/m" detail={true}>
            <IonLabel>
              <IonIcon icon={compassOutline} color="warning" /> Zur alten
              mobilen Version
            </IonLabel>
          </IonItem>
          <IonItem href="https://www.cleefchat.de/chat" detail={true}>
            <IonLabel>
              <IonIcon icon={compassOutline} color="warning" /> Zur Webansicht
            </IonLabel>
          </IonItem>
          <IonItem
            button
            detail={false}
            onClick={() => {
              logout();
            }}
          >
            <IonLabel color="danger">
              <IonIcon icon={exitOutline} color="danger" /> Chat verlassen
              (Logout)
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default connect({
  mapStateToProps: (state) => ({
    myId: state.user.user_id,
    avatar: state.user.avatar,
    name: state.user.username,
    priv: state.user.user_priv,
    coins: state.messages.coins,
    pinnwandPermission: state.user.perms_pinnwand,
    adminPermission: state.user.perms_admin_link,
    daily_prize: state.user.dailyPrize,
    status: state.user.current_status || "status_online",
    statuses: state.user.status,
    sysmess: state.user.sys_messages,
    lockprivat: state.user.lockprivat,
    darkMode: state.user.darkMode,
  }),
  mapDispatchToProps: {
    setDarkMode,
    changeStatus,
    changePvLock,
    systemMessage,
    logout,
  },
  component: withRouter(Setting),
});
