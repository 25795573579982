import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonList,
  IonItem,
  useIonAlert,
  IonInput,
} from "@ionic/react";
import { connect } from "../data/connect";
import { fetchChangePw } from "../data/chatApi";

interface ChangePwProps {
  user_id: string;
  priv: string;
  onDismiss: () => void;
}

const ChangePw: React.FC<ChangePwProps> = ({ user_id, priv, onDismiss }) => {
  const [present] = useIonAlert();
  const [fields, setFields] = useState({ oldpw: "", newpw: "", repeatpw: "" });

  const changePwHandler = () => {
    if (fields.newpw.length < 1) {
      present({ message: "Please enter a new password", buttons: ["ok"] });
      return false;
    }
    // eslint-disable-next-line
    if (fields.newpw != fields.repeatpw) {
      present({
        message: "The new password and repeat aren't identicla!",
        buttons: ["ok"],
      });
      return false;
    }
    fetchChangePw({ modpw: fields.newpw }).then((res: any) => onDismiss());
  };

  return (
    <>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Change Password</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                onDismiss();
              }}
            >
              schließen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonInput
              value={fields.oldpw}
              placeholder="Old Password"
              type="password"
              onIonChange={(e) =>
                setFields({ ...fields, oldpw: e.detail.value || "" })
              }
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonInput
              value={fields.newpw}
              placeholder="New Password"
              type="password"
              onIonChange={(e) =>
                setFields({ ...fields, newpw: e.detail.value || "" })
              }
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonInput
              value={fields.repeatpw}
              placeholder="Repeat New Password"
              type="password"
              onIonChange={(e) =>
                setFields({ ...fields, repeatpw: e.detail.value || "" })
              }
            ></IonInput>
          </IonItem>
        </IonList>
        <IonButton
          className="ion-margin"
          expand="block"
          onClick={() => changePwHandler()}
        >
          Submit
        </IonButton>
      </IonContent>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    priv: state.user.user_priv,
  }),
  mapDispatchToProps: {},
  component: ChangePw,
});
