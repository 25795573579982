import RegisterPw from "../sections/RegisterPw";
import { IonButton, useIonModal } from "@ionic/react";
import React from "react";
import { connect } from "../data/connect";

interface StateProps {
  reason: string;
  dismiss: any;
}

interface DispatchProps {}

interface ChatBubbleProps extends StateProps, DispatchProps {}
const RegisterWin: React.FC<ChatBubbleProps> = ({ dismiss, reason }) => {
  const registerDismiss = () => {
    closeRegister();
  };
  const [registerWin, closeRegister] = useIonModal(RegisterPw, {
    onDismiss: registerDismiss,
  });
  return (
    <div className="ion-padding">
      <p>
        Du musst deinen Namen im Chat <b>kostenlos registrieren</b>, um z. B.{" "}
        {reason} zu können.
      </p>
      <b>Welche Vorteile habe ich dadurch?</b>
      <ul>
        <li>absolut kostenlos und ohne Angabe von persönlichen Daten</li>
        <li>detaillierte Benutzerstatistik, wie z. B. Online-Zeit, uvm.</li>
        <li>Zutritt zu bestimmten Räumen</li>
        <li>Bilder-Upload</li>
        <li>Freundesliste</li>
        <li>Online-Games</li>
        <li>Chat-Quiz mit tollen Features</li>
      </ul>
      <IonButton
        expand="block"
        onClick={() => {
          dismiss();
          registerWin();
        }}
      >
        Passwort registrieren
      </IonButton>
    </div>
  );
};
export default connect({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: RegisterWin,
});
