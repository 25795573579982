import { Message } from "../models/Message";
import { Recent } from "../models/Recent";
import { AppState } from "./state";

export const getUserOnlineById = (ol: any, user_id: string): Recent => {
  let user: any = {};
  //console.log(state.onlinelist);
  if (ol.userOnline)
    ol.userOnline.forEach((ol: any) => {
      // eslint-disable-next-line
      if (ol.user_id == user_id) {
        user = ol;
      }
    });
  return {
    user_id,
    user: user.user || "unknown",
    user_priv: user.user_priv || "gast",
    user_avatar: user.user_avatar || "/avatars/def.png",
    user_sex: user.user_sex || "n",
    sender: user_id,
    message: "",
    time: "",
    friend: user.friend || "2",
  };
};
export const getOnlineById = (oli: any, user_id: string) => {
  let user: any = false;
  if (oli.userOnline)
    oli.userOnline.forEach((ol: any) => {
      // eslint-disable-next-line
      if (ol.user_id == user_id) user = ol;
    });

  return user;
};

export const getChatInfo = (state: AppState, mess: any, receiptor: string) => {
  // eslint-disable-next-line
  if (mess.user_id == receiptor)
    return {
      user_id: receiptor,
      user: mess.user || "unknown",
      user_priv: mess.priv || "gast",
      user_avatar: mess.avatar || "/avatars/def.png",
      user_sex: mess.sex || "n",
    };
  if (
    state.privatChats[receiptor] &&
    (state.privatChats[receiptor] as any).user !== "unknown"
  )
    return state.privatChats[receiptor];

  return getUserOnlineById(state.onlinelist, receiptor);
};

export const getGroupedMessages = (
  messages: { [k: string]: Message },
  unread: number
) => {
  let previousId = "0";
  let group: object[] = [];
  let grouped: object[] = [];
  const keys = Object.keys(messages);
  const unreadoffset = unread > 0 ? keys.slice(-unread)[0] : null;
  Object.keys(messages).forEach((id: string, key) => {
    let message = messages[id];
    // eslint-disable-next-line
    if (
      message.user_id != previousId ||
      (unreadoffset && message.id == unreadoffset)
    ) {
      previousId = message.user_id;
      if (unreadoffset && message.id == unreadoffset)
        grouped = [...grouped, [{ type: "unread" }]];
      if (group.length) grouped = [...grouped, [...group]];
      group = [message];
    } else group = [...group, message];
    // eslint-disable-next-line
    if (keys.length == key + 1) grouped = [...grouped, [...group]];
  });
  return grouped;
};
export const decodeHtml = (html: string) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};
export const textFormat = (text: string) =>
  text
    .replace(
      /<iframe[^>]*src="([^"]+)"[^>]*>[^<]*<\/iframe>/gi,
      '<a href="$1" target="_blank">Video gepostet</a>'
    )
    .replace(/<img[^>]*id="adminy_[^>]*\/?>/gi, "")
    .replace(/href="\//gi, 'href="https://www.cleefchat.de/')
    .replace(/src="\//gi, 'src="https://www.cleefchat.de/')
    .replace(/src="img/gi, 'src="https://www.cleefchat.de/img')
    .replace(/oncontextmenu="return false;" ondragstart="return false;"/gi, "");
export const str_pad = (val: any) => {
  var valString = val + "";
  if (valString.length < 2) return "0" + valString;
  else return valString;
};
export const unixToTime = (unix: any) => {
  var date = new Date(unix * 1000);
  return str_pad(date.getHours()) + ":" + str_pad(date.getMinutes());
};
